import { useState } from "react";
import { Card, Tooltip, Input } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons"; 
import { AppConstants } from "../../../Appconstants";
import { connect } from "react-redux";
import { setLogisticDetailsData } from "../../../redux/actions/UI/createDocument";
const { TextArea } = Input;

interface ILogisticDetailsProps {
  setLogisticDetailsData: Function;
  dataSource: string;
}

const LogisticDetails: React.FC<ILogisticDetailsProps> = ({
  setLogisticDetailsData, dataSource
}) => {
 
  const [logisticDetails, setLogisticDetails] = useState("");

  const handleBlur = () => {
    const newData = logisticDetails
    ?.toString()
    ?.split("\n")
    ?.filter((line: string) => line.trim() !== "");
    setLogisticDetailsData(newData);
  };

  return (
    <>
      <Card
        className="custom-card no-header-border flex-grow-1"
      >
        <div className="card-content">
          <div className="title mb-20">
            {AppConstants.LOGISTIC_DETAILS.LOGISTIC_DETAILS_LABEL} &nbsp;
            <Tooltip title={AppConstants.LOGISTIC_DETAILS.LOGISTIC_DETAILS_LABEL}>
              <InfoCircleOutlined className="info-icon" />
            </Tooltip>
          </div> 
            {AppConstants.LOGISTIC_DETAILS.WRITE_LOGISTIC_DETAIL}     
          <TextArea
            style={{ resize: "none" }}
            rows={3}
            placeholder={AppConstants.LOGISTIC_DETAILS.WRITE_LOGISTIC_DETAIL}
            className="mb-20"
            maxLength={100}
            showCount
            value={dataSource ? dataSource : (logisticDetails && typeof logisticDetails === "string" ? logisticDetails : "")}
            onChange={(e) => setLogisticDetails(e.target.value)}
            onBlur={handleBlur}
          />
        </div>
      </Card>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  dataSource: state.ui.createDocumentData.documentLogisticDetailsData,
});

const mapDispatchToProps = (dispatch: any) => ({
  setLogisticDetailsData: (payload: any) => dispatch(setLogisticDetailsData(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LogisticDetails);
