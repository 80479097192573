import * as type from '../types/API/imageUploadTypes';
import { IAction } from './userMetadataReducer';

const initialState = {
    imageUrl: null,
    loading: false,
    error: null,
}

export const imageReducer = (state = initialState, action: IAction) => {
    switch (action.type) {
        case type.UPLOAD_IMAGE:
            return {
                ...state,
                loading: true,
            };
        case type.UPLOAD_IMAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                imageUrl: action.payload.url,
            };
        case type.UPLOAD_IMAGE_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        default:
            return state;
    }
}