import React, { useEffect, useRef, useState } from "react";
import type { MenuProps, TableProps } from "antd";
import { connect } from "react-redux";
import {
  Breadcrumb,
  Button, 
  Dropdown,
  Input,
  Layout,
  Pagination,
  Popconfirm,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { Content } from "antd/es/layout/layout";
import { useNavigate } from "react-router-dom";
import "./Document.css";
import { setDocumentCompanyData, setDocumentType } from "../../redux/actions/UI/createDocument";
import CompanyMetaDataModal from "./Documents/CompanyMetaDataModal";
import { getDocuments } from "../../redux/actions/API/documents";
import noResult from '../../Utility/images/noresult.png'
import { EditOutlined, DeleteOutlined, PlusOutlined, DownloadOutlined, FileOutlined } from "@ant-design/icons";
import { IDataResponse } from "../../redux/types/API/ApiResponse";
import Loader from "../../Home/Loader/Loader";
import { downloadExcel } from "../../Utility/images/DownloadExcel";

interface Option {
  value: string;
  label: string;
  children?: Option[];
}

interface IDocumentProps {
  setDocumentCompanyData: Function;
  setDocumentType: Function;
  getDocuments: Function;
  documents: any;
  documentsState: IDataResponse;
  companyId: string;
}

interface DataType {
  key: string;
  documentNumber: string;  
  documentType: string; 
  companyName: string; 
  buyerName: string;        
  enquiryNumber: number;    
  ocCreated: boolean;       
  dealStatus: string;       
  dealOwner: string;        
  nextActionDate: string;   
  createdAt: string;        
}


const Documents: React.FC<IDocumentProps> = ({ setDocumentCompanyData, setDocumentType, getDocuments, documents, companyId, documentsState}) => {
  const prevPropsRef = useRef<IDocumentProps>(); 
  const navigate = useNavigate();
  const [modalTitle, setModalTitle] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [docType, setDocType] = useState<string>('');
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  useEffect(() => {
    getDocuments({ companyId: Number(companyId) })
  }, []);

  useEffect(() => {
    console.log(prevPropsRef);
    if (prevPropsRef.current?.documentsState?.loading && !documentsState?.loading ) {
      if (documents?.error?.length > 0) { 
        setLoading(false); 
      } else {
        setLoading(false); 
      }
    } 
    prevPropsRef.current = {setDocumentCompanyData,documentsState, setDocumentType, getDocuments, documents, companyId};
  }, [getDocuments,documentsState, documents]);

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
      timeZone: 'UTC',
    };
    return date.toLocaleString('en-GB', options);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredDocuments = documents?.filter((doc: DataType) => {
    const searchLower = searchTerm.toLowerCase();
    return (
      doc.documentNumber?.toLowerCase().includes(searchLower) ||
      doc.documentType?.toLowerCase().includes(searchLower) ||
      doc.buyerName?.toLowerCase().includes(searchLower) ||
      doc.enquiryNumber?.toString().includes(searchLower) ||
      (doc.ocCreated ? "Yes" : "No").toLowerCase().includes(searchLower) ||
      doc.dealStatus?.toLowerCase().includes(searchLower) ||
      doc.dealOwner?.toLowerCase().includes(searchLower) ||
      formatDate(doc.nextActionDate)?.toLowerCase().includes(searchLower) ||
      formatDate(doc.createdAt)?.toLowerCase().includes(searchLower)
    );
  });
  
  const onChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPageSize(pageSize); 
  };

  const columns: TableProps<DataType>["columns"] = [
    {
      title: "Doc No.",
      dataIndex: "documentNumber",
      key: "documentNumber",
      render: (docNumber: string, record: any) => <><span className="link" onClick={() => {
        navigate("/previewDocument?documentNumber=" + docNumber);
      }}>{docNumber} </span></>,
    },
    {
      title: "Doc Type",
      dataIndex: "documentType",
      key: "documentType",
    },
    { title: "Company", dataIndex: "buyerName", key: "buyerName" },
    { title: "Enquiry No.", dataIndex: "enquiryNumber", key: "enquiryNumber" },
    {
      title: "OC Created",
      dataIndex: "ocCreated",
      key: "ocCreated",
      render: (ocCreated) => (ocCreated ? "Yes" : "No"),
    },
    { title: "Deal Status", dataIndex: "dealStatus", key: "dealStatus", render: (docNumber: string, record: any) => <>{record.status == 0 && <Tag color="blue" className="mt-10">Draft</Tag>}</> },
    { title: "Deal Owner", dataIndex: "dealOwner", key: "dealOwner" },
    {
      title: "Next Action Date",
      dataIndex: "nextActionDate",
      key: "nextActionDate",
      // render: (text) => formatDate(text), 
    },
    {
      title: "Created Date", dataIndex: "createdAt", key: "createdAt",
      render: (text) => formatDate(text),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_: any, record: any) => (
        <Space size="middle">
          <Tooltip title="Edit Document">
            <span className="actionIcons" onClick={() => { }}>
              <EditOutlined />
            </span>
          </Tooltip>
          <Tooltip title="Delete">
            <Popconfirm
              title="Are you sure to delete this?"
              onConfirm={() => { }}
              okText="Yes"
              cancelText="No"
            >
              <span className="actionIcons"><DeleteOutlined /></span>
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ];

  const items: MenuProps["items"] = [
    { label: "Sales Enquiry", key: "salesEnquiry", icon: <FileOutlined/> },
    { label: "Sales Quotation", key: "salesQuotation", icon: <FileOutlined/> },
    { label: "Order Confirmation", key: "orderConfirmation", icon: <FileOutlined/> },
    { label: "Delivery Challan", key: "deliveryChallan", icon: <FileOutlined/> },
    { label: "Invoice", key: "invoice", icon: <FileOutlined/> },
    { label: "Sales Return", key: "salesReturn", icon: <FileOutlined/> }
  ];

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    const clickedItem: any = items.find((item: any) => item.key === e.key);
    setDocType(clickedItem?.key);
    setModalTitle(clickedItem?.label);
    setDocumentType(clickedItem?.label);
    setIsModalOpen(true);
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const onSubmit = (companyData: any) => {
    setDocumentCompanyData(companyData);
    navigate("/create_document?documentType=" + docType);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
    <Loader loading={loading}></Loader>
      <Layout className="layout">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Breadcrumb style={{ margin: "20px 0", flex: 1 }}>
            <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item>Sales</Breadcrumb.Item>
            <Breadcrumb.Item>Documents</Breadcrumb.Item>
          </Breadcrumb>
          <div className="mt-10"></div>
        </div>

        <Content className="content-section">
          <div className="mb-10">
            <div className="flexBox">
              <div>
                <Space wrap>
                  <Dropdown menu={menuProps} trigger={["click"]}>
                    <Button type="link">
                      <PlusOutlined /> Create Document
                    </Button>
                  </Dropdown>
                </Space>
                <Button
                  type="link"
                  onClick={() => {downloadExcel(documents, 'documents');}}
                  className="buttonAddItem"
                >
                  <DownloadOutlined /> Download
                </Button>
              </div>
              <div>
                <Input placeholder="Search..." style={{ width: 200 }} value={searchTerm} onChange={handleSearch} />
              </div>
            </div>
          </div>
          {documents?.length > 0 ? (
            <>
              <Table
                columns={columns}
                dataSource={filteredDocuments}
                bordered
                pagination={false}
              />
              <Pagination
                total={filteredDocuments?.length} 
                defaultPageSize={20}
                current={currentPage}
                pageSize={pageSize}
                onChange={onChange}
                showTotal={(total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`
                }
                style={{ textAlign: "center", marginTop: 20 }}
              />
            </>
          ) : (
            <div className="noData">
              <img src={noResult} alt="documentsData" />
              <p className="mt-20">
                You have not created any documents yet. Please create a document
              </p>
            </div>
          )}
          <CompanyMetaDataModal
            title={modalTitle}
            isModalOpen={isModalOpen}
            handleSubmit={onSubmit}
            handleCancel={handleCancel}
          />
        </Content>
      </Layout>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  documents: state.api.getDocuments?.data?.reverse(),
  documentsState: state.api.getDocuments,
  companyId: state.api.login?.data?.companyId
});

const mapDispatchToProps = (dispatch: any) => ({
  setDocumentCompanyData: (payload: any) => dispatch(setDocumentCompanyData(payload)),
  setDocumentType: (payload: any) => dispatch(setDocumentType(payload)),
  getDocuments: (payload: any) => dispatch(getDocuments(payload)),
});

export default connect(mapStateToProps,  mapDispatchToProps)(Documents);
