import {
  Breadcrumb,
  Button,
  Checkbox,
  Col,
  Divider,
  Drawer,
  Form,
  Input,
  Layout,
  List,
  notification,
  Popconfirm,
  Radio,
  Row,
  Select,
  Space,
  Tabs,
  Tag,
  Tooltip,
  Upload,
} from "antd";
import { Content } from "antd/es/layout/layout";
import React, { ChangeEvent } from "react";
import { FormInstance } from "antd/lib/form";
import { connect } from "react-redux";
import {
  addAddress,
  deleteAddress,
  editAddress,
  getAddress,
  setDefaultAddress,
} from "../../redux/actions/API/addressActions";
import { IDataResponse } from "../../redux/types/API/ApiResponse";
import Loader from "../../Home/Loader/Loader";
import { AppConstants } from "../../Appconstants";
import './ProfileManagement.css';
import {
  EditOutlined, DeleteOutlined, PlusOutlined
} from "@ant-design/icons";
import { stateData } from "../utility/StateData";
import logo from "../../Utility/images/ease/withBackground/ease-sub-white-bg.png";
import SeriesMetaDataModal from "./ProfileDocumentSeries";
import PaymentData from "./PaymentData";
import { getDocumentSeries } from "../../redux/actions/API/documentSeriesAction";
import { getBankDetail } from "../../redux/actions/API/bankDetail";
type NotificationType = "success" | "info" | "warning" | "error";

const { Option } = Select;

interface IProfileState {
  drawerOpen: boolean;
  editItem: any | null;
  submittedData: any[];
  addressLineOne: string;
  addressLineTwo: string;
  pincode: string;
  city: string;
  state: string;
  country: string;
  isEditAddress: boolean;
  loading: boolean;
  forTitle: boolean;
  searchData: string;
  filteredData: any;
  selectedCountry: string;
  statesList: string[];
  addressType: number[];
  selectedTab: string;
  fileList: any[];
  isVisible: boolean,
  documentNumbers: any[],
  selectedDocumentNumber: string,
}

interface IProfileProps {
  addressData: any;
  getAddressState: IDataResponse;
  addAddressState: IDataResponse;
  editAddressState: IDataResponse;
  deleteAddressState: IDataResponse;
  userId: number;
  companyId: any;
  addAddress: Function;
  getAddress: Function;
  editAddress: Function;
  deleteAddress: Function;
  setDefaultAddress: Function,
  setDefaultAddressState: IDataResponse,
  getDocumentSeries: Function
}

const states: Record<string, string[]> = stateData;

class ProfileManagement extends React.Component<IProfileProps, IProfileState> {
  private formRef = React.createRef<FormInstance>();

  constructor(props: IProfileProps) {
    super(props);
    this.state = {
      drawerOpen: false,
      forTitle: true,
      submittedData: [],
      editItem: null,
      addressLineOne: "",
      addressLineTwo: "",
      pincode: "",
      city: "",
      state: "",
      country: "India",
      isEditAddress: true,
      loading: true,
      searchData: "",
      filteredData: this.props.addressData,
      selectedCountry: "India",
      statesList: states["India"],
      addressType: [],
      selectedTab: 'Edit Profile',
      isVisible: false,
      documentNumbers: [],
      selectedDocumentNumber: '',
      fileList: [
        {
          uid: '-1',
          name: 'logo.png',
          status: 'done',
          url: logo,
        },
      ],
    };
  }

  renderAccountInformation = () => {
    return <>
      <div>
        <Form
          layout="horizontal"
          // onFinish={handleFormSubmit}
          initialValues={{
            companyName: 'Ease Margin PVT LTD',
            companyEmail: 'admin@easemargin.com',
            companyContact: '7416585067',
            companyWebsite: 'www.easemargin.com',
            name: 'Rahul Deo Burman',
            personalContact: '7416141020',
            personalEmail: 'rdburman@easemargin.com',
            role: 'Super admin',
          }}
        >
          <Form.Item label="Company Name" name="companyName">
            <Input readOnly className="input-company-name" />
          </Form.Item>
          <Form.Item label="Company Email" name="companyEmail">
            <Input readOnly className="input-company-email" />
          </Form.Item>
          <Form.Item label="Company Contact No" name="companyContact">
            <Input readOnly className="input-contact" />
          </Form.Item>
          <Form.Item label="Company Website" name="companyWebsite">
            <Input readOnly className="input-company-website" />
          </Form.Item>
          <div className="form-user-details">
            <Form.Item label="Name" name="name">
              <Input className="input-name" />
            </Form.Item>
            <Form.Item label="Contact No" name="personalContact">
              <Input className="input-user-contact" />
            </Form.Item>
            <Form.Item label="Email" name="personalEmail">
              <Input className="input-email" />
            </Form.Item>
            <Form.Item label="Role" name="role">
              <Input className="input-role" />
            </Form.Item>
          </div>
        </Form>
        <Form>
          <Form.Item>
            <Row>
              <Button type="primary" htmlType="submit">
                Save Changes
              </Button>
            </Row>
          </Form.Item>
        </Form>
      </div>
    </>
  }

  renderAddress = () => {
    return <>
      <div className="companyAddressDetails">
        <div className="flexBox">
          <p>Manage addresses here</p>
          <Button
            type="link"
            onClick={this.onAddAddress}
            style={{ marginLeft: "auto" }}
          >
            Add New Address
          </Button>
        </div>
        <div className="companyDetailsContent">
          <List
            className="list-container-address"
            itemLayout="horizontal"
            dataSource={this.state.filteredData}
            renderItem={(item: any, index: number) => (
              <>
                <List.Item
                  key={item.id}
                  actions={[
                    <div className="action-address">
                      <Tooltip title="Edit Details">
                        <p
                          onClick={() => this.handleEdit(item)}
                          className="actionIcons"
                        >
                          <EditOutlined />
                        </p>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <Popconfirm
                          title="Are you sure to delete this?"
                          onConfirm={() => this.handleDelete(item.id)}
                          okText="Yes"
                          cancelText="No"
                        >
                          <p className="actionIcons"><DeleteOutlined /></p>
                        </Popconfirm>
                      </Tooltip>
                    </div>
                  ]}
                >
                  <List.Item.Meta
                    title={
                      <div>{this.generateBindData()[index].addressType}</div>
                    }
                    description=
                    {<div className="addressDesc">{item.addressLineOne}, {item.addressLineTwo}, {item.city}, {item.state}, {item.country} - {item.pincode}</div>}
                  />
                  {item.status == 0 ? <Tag color={'green'} key={1}>
                    {'Default'}
                  </Tag> : <Tag color={'geekblue'} key={1} onClick={() => {
                    this.setState({ loading: true });
                    this.props.setDefaultAddress({ companyId: this.props.companyId, addressId: item.id })
                  }}
                    style={{ cursor: "pointer" }}>
                    {'Set as Default'}
                  </Tag>}
                </List.Item>
              </>
            )}
          />
        </div>
      </div>
    </>
  }

  profileTabArray = () => {
    let tabsData: any[] = [{
      label: 'Account Information',
      key: 1,
      children: this.renderAccountInformation(),
    },
    {
      label: 'Addresses',
      key: 2,
      children: this.renderAddress(),
    },
    {
      label: 'Document Series',
      key: 3,
      children: <SeriesMetaDataModal/>,
    }, {
      label: 'Bank Details',
      key: 4,
      children: <PaymentData />,
    }
    ];
    return tabsData;
  }

  getAddressType = (addressType: number) => {
    return addressType ===
      AppConstants.ADDRESS_TYPE_MAPPING.BILLING_ADDRESSES.ENTITY_ID
      ? AppConstants.ADDRESS_TYPE_MAPPING.BILLING_ADDRESSES.DISPLAY_STRING
      : AppConstants.ADDRESS_TYPE_MAPPING.DELIVERY_ADDRESSES.DISPLAY_STRING;
  };

  generateBindData = () => {
    return this.state.filteredData.map((address: any) => ({
      addressType: this.getAddressType(address.addressType),
    }));
  };

  componentDidMount(): void {
    this.props.getAddress({ companyId: Number(this.props.companyId) });
    this.props.getDocumentSeries({ companyId: Number(this.props.companyId) });
  }

  componentDidUpdate(
    prevProps: Readonly<IProfileProps>,
    prevState: Readonly<IProfileState>,
    snapshot?: any
  ): void {
    if (
      prevProps.getAddressState?.loading &&
      !this.props.getAddressState?.loading
    ) {
      if (this.props.getAddressState?.error?.length > 0) {
        this.openNotificationWithIcon("error", "Failed to add address");
      } else {
        this.setState({
          loading: false,
          filteredData: this.filterData(this.props.addressData),
        });
      }
    }

    if (
      prevProps.addAddressState?.loading &&
      !this.props.addAddressState?.loading
    ) {
      if (this.props.addAddressState?.error?.length > 0) {
        this.openNotificationWithIcon("error", "Failed to add address");
      } else {
        this.props.getAddress({
          companyId: Number(this.props.companyId),
        });
        this.openNotificationWithIcon("success", "Address added successfully");
      }
    }

    if (
      prevProps.setDefaultAddressState?.loading &&
      !this.props.setDefaultAddressState?.loading
    ) {
      if (this.props.setDefaultAddressState?.error?.length > 0) {
        this.openNotificationWithIcon("error", "Failed to set default address");
      } else {
        this.props.getAddress({
          companyId: Number(this.props.companyId),
        });
        this.openNotificationWithIcon("success", "Address set as default successfully");
      }
    }

    if (
      prevProps.editAddressState?.loading &&
      !this.props.editAddressState?.loading
    ) {
      if (this.props.editAddressState?.error?.length > 0) {
        this.openNotificationWithIcon("error", "Failed to update address");
      } else {
        this.props.getAddress({
          companyId: Number(this.props.companyId),
        });
        this.openNotificationWithIcon(
          "success",
          "Address updated successfully"
        );
        this.setState({
          drawerOpen: false,
          isEditAddress: false,
          editItem: null,
        });
      }
    }

    if (
      prevProps.deleteAddressState?.loading &&
      !this.props.deleteAddressState?.loading
    ) {
      if (this.props.deleteAddressState?.error?.length > 0) {
        this.openNotificationWithIcon("error", "Failed to delete address");
      } else {
        this.props.getAddress({
          companyId: Number(this.props.companyId),
        });
        this.openNotificationWithIcon(
          "success",
          "Address deleted successfully"
        );
      }
    }
    if (prevProps.addressData !== this.props.addressData) {
      this.setState({
        filteredData: this.filterData(this.props.addressData),
      });
    }
  }

  openNotificationWithIcon = (type: NotificationType, message: string) => {
    notification[type]({
      message: message,
      duration: 2,
    });
  };

  filterData = (data: any[]) => {
    return data.filter(
      (item: {
        addressType: string;
        addressLineOne: string;
        addressLineTwo: string;
        city: string;
        state: string;
        country: string;
        pincode: string;
      }) =>
        item.addressLineOne.toLowerCase().includes(this.state.searchData) ||
        (item.addressLineTwo
          ? item.addressLineTwo.toLowerCase().includes(this.state.searchData)
          : false) ||
        item.city.toLowerCase().includes(this.state.searchData) ||
        item.state.toLowerCase().includes(this.state.searchData) ||
        item.country.toLowerCase().includes(this.state.searchData) ||
        (item.pincode
          ? item.pincode.toLowerCase().includes(this.state.searchData)
          : false)
    );
  };

  onAddAddress = () => {
    this.setState({
      drawerOpen: true,
      editItem: null,
      forTitle: true,
      isEditAddress: false,
    });
  };

  onClose = () => {
    this.setState({
      drawerOpen: false,
      isEditAddress: false,
      editItem: null,
    });
    this.formRef.current?.resetFields();
  };

  onChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value.toLowerCase();

    this.setState({
      searchData: query,
      filteredData: this.props.addressData.filter((item: any) => {
        // convert addressType handle gracefully
        const addressTypeString = this.getAddressType(
          item.addressType
        ).toLowerCase();
        return (
          addressTypeString.includes(query) ||
          item.addressLineOne.toLowerCase().includes(query) ||
          (item.addressLineTwo
            ? item.addressLineTwo.toLowerCase().includes(query)
            : false) ||
          item.city.toLowerCase().includes(query) ||
          item.state.toLowerCase().includes(query) ||
          item.country.toLowerCase().includes(query) ||
          (item.pincode ? item.pincode.toLowerCase().includes(query) : false)
        );
      }),
    });
  };

  handleSubmit = () => {
    this.setState({
      loading: true,
    });
    this.formRef.current
      ?.validateFields()
      .then((values) => {
        if (this.state.isEditAddress) {
          // Edit existing address
          this.props.editAddress({
            companyId: Number(this.props.companyId),
            addressId: this.state.editItem.id,
            ip_address: "127.0.0.1",
            storeType: values.storeType,
            addressLineOne: values.addressLineOne,
            addressLineTwo: values.addressLineTwo,
            pinCode: values.pincode,
            city: values.city,
            state: values.state,
            country: values.country,
            addressType: values.addressType,
          });
        } else {
          this.props.addAddress({
            companyId: Number(this.props.companyId),
            ip_address: "127.0.0.1",
            addressLineOne: values.addressLineOne,
            addressLineTwo: values.addressLineTwo,
            pinCode: values.pincode,
            city: values.city,
            state: values.state,
            country: values.country,
            addressType: values.addressType,
          });

          this.setState((prevState) => ({
            submittedData: [
              ...prevState.submittedData,
              {
                id: Date.now(),
                addressLineOne: values.addressLineOne,
                addressLineTwo: values.addressLineTwo,
                pincode: values.pincode,
                storeType: values.storeType,
                city: values.city,
                state: values.state,
                country: values.country,
                addressType: values.addressType,
              },
            ],
          }));
        }
        this.formRef.current?.resetFields();
        this.setState({ drawerOpen: false, editItem: null });
      })
      .catch((errorInfo) => {
        console.log("Validate Failed:", errorInfo);
        this.setState({
          loading: false,
        });
      });
  };

  handleEdit = (values: any) => {
    console.log(values);
    const addressTypeArray: string[] = [];
    const addressType = values.addressType;
    console.log(values.addressType);
    if (addressType && typeof addressType === "number") {

      if (addressType == 2) {
        addressTypeArray.push("Billing Address");
      }
      if (addressType == 1) {
        addressTypeArray.push("Delivery Address");
      }
    }

    this.setState(
      {
        drawerOpen: true,
        editItem: values,
        forTitle: false,
        isEditAddress: true,
        addressType: [values.addressType]
      },

      () => {
        if (this.formRef.current) {
          this.formRef.current.setFieldsValue({
            addressLineOne: values.addressLineOne,
            addressLineTwo: values.addressLineTwo,
            pincode: values.pincode,
            city: values.city,
            state: values.state,
            country: values.country,
            addressType: [values.addressType],
          });
        }
      }
    );
  };

  handleDelete = (storeId: number) => {
    this.setState({
      loading: true,
    });
    this.props.deleteAddress({
      storeId: storeId,
    });
  };

  handleStateChange = (value: string) => {
    this.setState({ state: value });
  };

  handleUploadChange = ({ fileList }: any) => {
    this.setState({ fileList });
  };


  render() {
    const { drawerOpen, filteredData, fileList } = this.state;
    return (
      <>
        <Loader loading={this.state.loading}></Loader>
        <Layout
          className="layout-main layout"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Breadcrumb
              style={{
                margin: "16px 0",
                flex: 1, // Take up remaining space
              }}
            >
              <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
              <Breadcrumb.Item>Profile Management</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <Content
            className="content-section profileWrapper"
          >
            <div>
              <div className="profileBody">
                <div className="profileCover"></div>
                <div className="profileLogoBody">
                  <div className="logoAndDetails">
                    <img
                      className="profileLogo"
                      src={logo}
                      alt="Logo"
                    />
                    <div className="profileDetails">
                      <div className="companyName">Ease Margin PVT LTD</div>
                      <div>www.easemagin.com</div>
                    </div>
                  </div>
                </div>
              </div>

              <Tabs
                tabPosition={'left'}
                items={this.profileTabArray()}
              />
            </div>
          </Content>
        </Layout>

        <Drawer
          visible={drawerOpen}
          title={this.state.forTitle ? "Add an address" : "Edit an address"}
          width={720}
          onClose={this.onClose}
          className="drawer-address"
          extra={
            <Space>
              <Button onClick={this.onClose}>Cancel</Button>
              <Button
                onClick={this.handleSubmit}
                type="primary"
                form="addressForm"
                key="submit"
                htmlType="submit"
              >
                {this.state.forTitle ? "Submit" : "Update"}
              </Button>
            </Space>
          }
        >
          <Form
            layout="vertical"
            onFinish={this.handleSubmit}
            ref={this.formRef}
          >
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="addressLineOne"
                  label="Address Line 1"
                  rules={[
                    { required: true, message: "Please enter an address" },
                  ]}
                >
                  <Input placeholder="Please enter an address" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item name="addressLineTwo" label="Address Line 2">
                  <Input placeholder="please enter address description" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="pincode"
                  label="Pin Code"
                  rules={[
                    { required: false, message: "Please enter a pincode" },
                    {
                      pattern: /^\d{6}$/,
                      message: "Pin code must be a 6-digit number",
                    },
                  ]}
                >
                  <Input placeholder="please enter pincode" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="city"
                  label="City"
                  rules={[
                    { required: true, message: "Please enter your city" },
                    {
                      max: 35,
                      message: "City must be less than 35 characters",
                    },
                    {
                      pattern: /^[A-Za-z\s]*$/,
                      message:
                        "City must be a string with no numbers or special characters",
                    },
                  ]}
                >
                  <Input placeholder="please enter your city" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="state"
                  label="State"
                  rules={[
                    { required: true, message: "Please enter your state" },
                  ]}
                >
                  <Select
                    placeholder="Select a state"
                    onChange={this.handleStateChange}
                    value={this.state.state}
                    disabled={!this.state.selectedCountry}
                  >
                    {this.state.statesList.map((state) => (
                      <Option key={state} value={state}>
                        {state}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="country"
                  label="Country"
                  rules={[
                    { required: true, message: "Please select a country" },
                  ]}
                >
                  <Select
                    placeholder="Select a country"
                    value={"India"}
                  >
                    <Option value="India">India</Option>
                    {/* Add more countries here if needed */}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              name="addressType"
              label="Address Type"
              rules={[{ required: true, message: "Please enter address type" }]}
            >
              <Checkbox.Group style={{ width: "100%", marginLeft: "0px" }}>
                <Row>
                  <Col span={12}>
                    <Checkbox value={1}>Delivery Address</Checkbox>
                  </Col>
                  <Col span={12}>
                    <Checkbox value={2}>Billing Address</Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Form>
        </Drawer>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  addressData: state.api.getAddress?.data?.reverse(),
  userId: state.api.login.data.id,
  companyId: state.api.login.data.companyId,
  getAddressState: state.api.getAddress,
  addAddressState: state.api.addAddress,
  editAddressState: state.api.editAddress,
  deleteAddressState: state.api.deleteAddress,
  setDefaultAddressState: state.api.setDefaultAddress,
});

const mapDispatchToProps = (dispatch: any) => ({
  addAddress: (payload: any) => dispatch(addAddress(payload)),
  getAddress: (payload: any) => dispatch(getAddress(payload)),
  editAddress: (payload: any) => dispatch(editAddress(payload)),
  deleteAddress: (payload: any) => dispatch(deleteAddress(payload)),
  setDefaultAddress: (payload: any) => dispatch(setDefaultAddress(payload)),
  getDocumentSeries: (payload: any) => dispatch(getDocumentSeries(payload)),
  getBankDetail: (payload: any) => dispatch(getBankDetail(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileManagement);
