import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  Tooltip,
  Button,
  Popconfirm,
  Layout,
  Breadcrumb,
  Input,
  notification,
  Tag,
  Pagination,
} from "antd";
import { EditOutlined, EyeOutlined, DeleteOutlined, DownloadOutlined, UploadOutlined, PlusOutlined } from "@ant-design/icons";
import { Content } from "antd/es/layout/layout";
import AddEditBuyerSupplier from "./Drawer";
import './BuyerSupplier.css';
import { connect } from "react-redux";
import { deleteBuyerSupplier, getBuyerSupplier } from "../../redux/actions/API/buyerSupplier";
import { IDataResponse } from "../../redux/types/API/ApiResponse";
import Loader from "../Loader/Loader";
import { AppConstants } from "../../Appconstants";
import { downloadExcel } from "../../Utility/images/DownloadExcel";
import BulkUpload from "../../Dashboard/Components/bulk-upload/BulkUpload";

interface IBuyerSupplierInterface {
  buyerSupplierData: any,
  deleteBuyerSupplier: Function,
  deleteBuyerSupplierState: IDataResponse,
  getBuyerSupplier: Function,
  companyId: number,
  getBuyerSupplierState: IDataResponse
}

const openNotificationWithIcon = (message: string) => {
  notification.open({
    message: message,
    type: 'success',
    duration: 3,
  });
};

const BuyerSupplier: React.FC<IBuyerSupplierInterface> = ({ buyerSupplierData, deleteBuyerSupplier, deleteBuyerSupplierState, getBuyerSupplier, companyId, getBuyerSupplierState }) => {
  const prevPropsRef = useRef<any>();
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [isEditBtn, setIsEditBtn] = useState(false);
  const [dataToHandle, setDataToHandle] = useState<any>(null);
  const [isViewMode, setIsViewMode] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false)

  useEffect(() => {
    if (prevPropsRef?.current?.deleteBuyerSupplierState?.loading && !deleteBuyerSupplierState?.loading) {
      if (deleteBuyerSupplierState?.error?.length > 0) {
        // handle error
      } else {
        getBuyerSupplier({
          companyId: companyId
        });
        setLoading(false);
        openNotificationWithIcon('Customer deleted successfully');
      }
    }

    if (prevPropsRef?.current?.getBuyerSupplierState?.loading && !getBuyerSupplierState?.loading) {
      if (getBuyerSupplierState?.error?.length > 0) {
        // handle error
      } else {
        setLoading(false);
      }
    }

    prevPropsRef.current = { getBuyerSupplier, getBuyerSupplierState, deleteBuyerSupplier, deleteBuyerSupplierState };
  }, [deleteBuyerSupplierState, deleteBuyerSupplier]);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
    setDataToHandle(null);
    setIsViewMode(false);
    setIsEditBtn(false);
  };

  const handleAdd = (newData: any) => {
    setData((prevData) => [...prevData, { ...newData, id: Date.now() }]);
  };

  const handleEdit = (updatedData: any) => {
    setIsOpen(!isOpen);
    setIsEditBtn(true);
    setIsViewMode(false);
    setIsEditMode(true);
    setData((prevData) =>
      prevData.map((item) =>
        item.id === updatedData ? { ...item, ...updatedData } : item
      )
    );
  };

  const handleMenuClick = (item: any, { key }: { key: string }) => {
    if (key === "view") {
      setIsOpen(true);
      setDataToHandle(item);
      setIsViewMode(true);
    } else if (key === "edit") {
      setIsEditMode(true);
      setIsEditBtn(true);
      setDataToHandle(item);
      setIsOpen(true);
    } else if (key === "delete") {
      handleDelete(item.id);
    }
  };

  const handleDelete = (id: number) => {
    setLoading(true);
    deleteBuyerSupplier({
      id: id
    });
  };

  const generateData = () => {
    let companyData: any[] = [];
    buyerSupplierData?.map((elem: any) => {
      companyData.push({
        id: elem.id,
        name: elem.name,
        companyName: elem.companyName,
        companyEmail: elem.companyEmail,
        companyTypes: elem.companyType == AppConstants.COMPANY_TYPE_MAPPING.BUYER.COMPANY_TYPE_ID ? AppConstants.COMPANY_TYPE_MAPPING.BUYER.COMPANY_TYPE : (elem.companyType == AppConstants.COMPANY_TYPE_MAPPING.SELLER.COMPANY_TYPE_ID ? AppConstants.COMPANY_TYPE_MAPPING.SELLER.COMPANY_TYPE : AppConstants.COMPANY_TYPE_MAPPING.BOTH.COMPANY_TYPE),
        companyType: elem.companyType,
        email: elem.email,
        GSTNumber: elem.GSTNumber,
        GSTType: elem.GSTType,
        phone: elem.phone,
        addresses: elem.addresses
      });
    });
    return companyData;
  };

  const columns = [
    {
      title: "Company Name",
      dataIndex: "companyName",
      key: "companyName",
    },
    {
      title: "Company Email",
      dataIndex: "companyEmail",
      key: "companyEmail",
    },
    {
      title: "Contact Person",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Contact No.",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Company Type",
      dataIndex: "companyTypes",
      key: "companyTypes",
      render: (_: any, record: any) => (
        <Tag color={record.companyTypes === 'Buyer' ? '#2db7f5' : (record.companyTypes === 'Seller' ? '#87d068' : '#669999')} key={1}>
          {record.companyTypes}
        </Tag>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text: any, record: any) => (
        <div style={{ display: "flex", gap: 8 }}>
          <Tooltip title="View Details">
            <span className="actionIcons" onClick={() => handleMenuClick(record, { key: "view" })}>
              <EyeOutlined />
            </span>
          </Tooltip>

          <Tooltip title="Edit">
            <span className="actionIcons" onClick={() => handleMenuClick(record, { key: "edit" })}>
              <EditOutlined />
            </span>
          </Tooltip>

          <Tooltip title="Delete">
            <Popconfirm
              title="Are you sure to delete this?"
              onConfirm={() => handleDelete(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <span className="actionIcons"><DeleteOutlined /></span>
            </Popconfirm>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <>
      <Loader loading={loading}></Loader>
      <Layout className="layout">
        <div className="flexBox mb-flexFlow">
          <Breadcrumb
            className="breadcrumb"
            style={{
              margin: "16px 0",
              flex: 1,
            }}
          >
            <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item>Customers</Breadcrumb.Item>
          </Breadcrumb>

          <div className="mt-10">
            {/* Additional buttons or components can be added here */}
          </div>
        </div>
        <Content className="content-section">
          <div className="mb-10">
            <div className="flexBox">
              <div>
                <Button
                  type="link"
                  onClick={toggleDrawer}
                >
                  <PlusOutlined /> Add Customer
                </Button>
                <Button
                  type="link"
                  onClick={() => {
                    downloadExcel(generateData(), 'customers');
                  }}
                >
                  <DownloadOutlined /> Download
                </Button>
                <Button
                  type="link"
                  onClick={() => setDrawerVisible(true)}
                >
                  <UploadOutlined /> Bulk Upload
                </Button>
              </div>
              <div>
                <Input
                  placeholder="Search Customer"
                  className="search-input" // Optional for specific styles
                />
              </div>
            </div>
          </div>
          <Table
            bordered
            className="table-container"
            columns={columns}
            dataSource={generateData()}
            rowKey="id"
            pagination={false}
            scroll={{ x: 768 }} // Scrollable table for mobile
          />
          <Pagination
            total={generateData()?.length}
            pageSizeOptions={['5', '10', '20', '50']}
            defaultPageSize={20}
            onChange={() => { /* Handle page change */ }}
            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
            style={{ textAlign: 'center', marginTop: 20 }}
          />
        </Content>
      </Layout>

      <AddEditBuyerSupplier
        visible={isOpen}
        onClose={toggleDrawer}
        onAdd={handleAdd}
        onEdit={handleEdit}
        dataToHandle={dataToHandle}
        isDisable={isViewMode}
        isEditMode={isEditMode}
      />
      <BulkUpload onClose={() => setDrawerVisible(false)} open={drawerVisible} title="Company Bulk Upload" uploadTemplateText="Company bulk upload template" templatePath="company-excel-template" resultTitle="Successfully created Company" resultSubTitle="Your excel file for company has been created successfully" />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  buyerSupplierData: state.api.getBuyerSupplier?.data?.reverse(),
  deleteBuyerSupplierState: state.api.deleteBuyerSupplier,
  getBuyerSupplierState: state.api.getBuyerSupplier,
  companyId: state.api.login.data.companyId,
});

const mapDispatchToProps = (dispatch: any) => ({
  deleteBuyerSupplier: (payload: any) => dispatch(deleteBuyerSupplier(payload)),
  getBuyerSupplier: (payload: any) => dispatch(getBuyerSupplier(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BuyerSupplier);
