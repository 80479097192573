import {
  SET_ADDITIONAL_DETAIL_DATA,
  SET_ADVANCE_PAYMENT,
  SET_BANK_DETAILS,
  SET_COMPANY_DATA,
  SET_DOCUMENT_ADDITIONAL_CHARGES,
  SET_DOCUMENT_ATTACHMENTS_DATA,
  SET_DOCUMENT_ITEM_FORM_DATA,
  SET_DOCUMENT_ITEM_GRID_DATA,
  SET_DOCUMENT_TYPE,
  SET_DOCUMENTS_SIGNATURE_DATA,
  SET_DOCUMENTS_TERMS_AND_CONDITION_DATA,
  SET_LOGISTIC_DETAIL_DATA,
  SET_SUPPLIER_DATA,
} from "../../types/UI/createDocument";

const initialState = {
  documentType: "",
  buyerDetails: [],
  supplierDetails: [],
  documentGridItemData: [],
  documentItemFormData: [],
  documentAdditionalChargesData: [],
  documentLogisticDetailsData: "",
  documentAdditionalDetailsData: "",
  documentAttachmentsData: [],
  documentSignatureData: [],
  documentTermsAndConditionData: "",
  documentBankDetails: [],
  advancePaymentData: 0,
};

export interface IAction {
  type: string;
  payload?: any;
}

const setDocumentItemGridData = (state = initialState, action: IAction) => {
  switch (action.type) {
    case SET_DOCUMENT_TYPE:
      return {
        ...state,
        documentType: action.payload,
      };

    case SET_COMPANY_DATA:
      return {
        ...state,
        buyerDetails: action.payload,
      };

    case SET_SUPPLIER_DATA:
      return {
        ...state,
        supplierDetails: action.payload,
      };

    case SET_DOCUMENT_ITEM_GRID_DATA:
      console.log(action.payload);
      return {
        ...state,
        documentGridItemData: action.payload,
      };

    case SET_DOCUMENT_ADDITIONAL_CHARGES:
      return {
        ...state,
        documentAdditionalChargesData: action.payload,
      };

    case SET_DOCUMENT_ITEM_FORM_DATA:
      return {
        ...state,
        documentItemFormData: action.payload,
      };

    case SET_LOGISTIC_DETAIL_DATA:
      return {
        ...state,
        documentLogisticDetailsData: action.payload,
      };

    case SET_ADDITIONAL_DETAIL_DATA:
      return {
        ...state,
        documentAdditionalDetailsData: action.payload,
      };

    case SET_DOCUMENT_ATTACHMENTS_DATA:
      return {
        ...state,
        documentAttachmentsData: action.payload,
      };

    case SET_DOCUMENTS_SIGNATURE_DATA:
      return {
        ...state,
        documentSignatureData: action.payload,
      };

    case SET_DOCUMENTS_TERMS_AND_CONDITION_DATA:
      return {
        ...state,
        documentTermsAndConditionData: action.payload,
      };

    case SET_BANK_DETAILS:
      return {
        ...state,
        documentBankDetails: action.payload,
      };

    case SET_ADVANCE_PAYMENT:
      return {
        ...state,
        advancePaymentData: action.payload,
      };

    default:
      return state;
  }
};

export default setDocumentItemGridData;
