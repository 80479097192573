import React, { useEffect, useRef, useState } from "react";
import { AutoComplete, Col, DatePicker, Input, Row, Select } from "antd";
import { connect } from "react-redux";
import { getStores } from "../../../redux/actions/API/storesActions";
import SeriesMetaDataModal from "../SeriesMetaData/SeriesMetaDataModal";
import { getDocumentSeries } from "../../../redux/actions/API/documentSeriesAction";
import { AppConstants } from "../../../Appconstants";
import "./Document.css";
import dayjs from "dayjs";
import { getDocuments } from "../../../redux/actions/API/documents";
import { setAdditionalDetailsData, setDocumentAdditionalChargesData, setDocumentItemFormData, setDocumentItemGridData, setDocumentTermsAndConditionsData, setLogisticDetailsData } from "../../../redux/actions/UI/createDocument";
import { SET_DOCUMENT_ITEM_GRID_DATA } from "../../../redux/types/UI/createDocument";

interface IDocumentItemFormProps {
  companyId: number;
  userId: number;
  dataSource: [];
  getStores: Function;
  storesData: any;
  seriesData: any;
  documentsData: any;
  setDocumentItemForm: Function;
  getDocumentSeries: Function;
  getDocuments: Function;
  documentType: string;
  documentValidationData: any;
  documentCompanyFormsData: any;
  setDocumentTermsAndConditionsData: Function,
  setLogisticDetailsData: Function,
  setAdditionalDetailsData: Function,
  setDocumentAdditionalChargesData: Function,
  setDocumentItemGridData: Function,
}

interface DocumentData {
  value: string;
  label: string;
  enquiryDate?: string;
}

const DocumentMetaDataForm: React.FC<IDocumentItemFormProps> = ({
  storesData,
  seriesData,
  documentsData,
  setDocumentItemForm,
  documentType,
  documentValidationData,
  documentCompanyFormsData,
  setDocumentTermsAndConditionsData,
  setLogisticDetailsData,
  setAdditionalDetailsData,
  setDocumentAdditionalChargesData,
  setDocumentItemGridData,
}) => {
  const [documentMetaData, setDocumentMetaData] = useState<any>({
    documentNumber: "",
    currentSeriesNumber: 0,
    seriesId: 0,
    documentDate: dayjs(), // initial date selected
    enquiryNumber: "",
    enquiryDate: "",
    deliveryDate: "",
    store: "",
    paymentTerm: "",
    ammendment: 0,
    expectedPaymentDate: "",
    POCName: "",
    POCNumber: "",
    expectedReplyDate: "",
    kindAttention: "",
    purchaseOrderNumber: "",
    purchaseOrderDate: "",
    quotationDate: '',
    orderConfirmationNumber: "",
    orderConfirmationDate: "",
    transporterName: "",
    transporterGSTNumber: "",
    transportationDocumentNumber: "",
    vehicleNumber: "",
    transportationDocumentDate: "",
    invoiceNumber: "",
    recievedDate: "",
    invoiceDate: "",
    creditNoteNumber: "",
    creditNoteDate: "",
    billDate: "",
  });
  const prevPropsRef = useRef<any>();
  const [documentNumber, setDocumentNumber] = useState<string>('');
  const [selectedDocument, setSelectedDocument] = useState<string | undefined>();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [storeDropdownData, setStoreDropdownData] = useState([]);
  const [docValidData, setDocValidData] = useState(documentValidationData);
  const [inputSeriesNumber, setInputSeriesNumber] = useState<string>("");

  useEffect(() => {
    if (prevPropsRef?.current?.documentValidationData !== documentValidationData) {
      console.log(documentValidationData);
      setDocValidData(documentValidationData);
    }
    prevPropsRef.current = {
      documentValidationData
    };
  }, [documentValidationData]);

  useEffect(() => {
    setDocumentItemForm({ documentMetaData, });
  }, []);

  useEffect(() => {
    console.log("Stores Data from Props:", storesData);
    generateSeriesData();
  }, [seriesData]);

  useEffect(() => {
    generateStoreData();
  }, [storesData]);

  const handleFieldChange = (field: string, value: string) => {
    setDocumentMetaData((prevFormData: any) => {
      const newFormData = { ...documentCompanyFormsData, [field]: value };
      setDocumentItemForm(newFormData);
      return newFormData;
    });
  };

  const handleSeriesModalClose = () => {
    setIsVisible(false);
  };

  const handleSeriesSelection = (selectedSeries: string) => {
    setSelectedDocument(selectedSeries);
    handleFieldChange("documentNumber", selectedSeries);
    handleSeriesModalClose();
  };

  const generateSeriesData = () => {
    let data: { value: string; label: string }[] = [];
    seriesData?.forEach((series: { seriesName: string }) => {
      data.push({ value: series.seriesName, label: series.seriesName });
    });

    const filteredSeries = seriesData?.filter((series: { default: number; DocType: string; }) => series.default === 1 && series.DocType == documentType);
    // console.log(filteredSeries);

    if (filteredSeries && filteredSeries.length > 0) {
      const { prefix, nextNumber } = filteredSeries[0];
      setDocumentNumber(`${prefix}-${nextNumber}`);
      setDocumentMetaData({
        ...documentMetaData,
        documentNumber: `${prefix}-${nextNumber}`,
        currentSeriesNumber: nextNumber,
        seriesId: filteredSeries[0].id
      });
      setDocumentItemForm({
        ...documentMetaData,
        documentNumber: `${prefix}-${nextNumber}`,
        currentSeriesNumber: nextNumber,
        seriesId: filteredSeries[0].id
      });
    } else {
      console.log("No default series found");
    }
  };

  const generateStoreData = () => {
    console.log("Original Stores Data:", storesData);
    let data: any = [];
    storesData?.map((store: any) => {
      console.log("Store Item:", store);
      data.push({ value: store.name, label: store.name });
    });
    console.log("Mapped Store Data:", data);
    setStoreDropdownData(data);
  };

  const calculateExpectedPaymentDate = (paymentTerm: string) => {
    let daysToAdd = 0;
    switch (paymentTerm) {
      case "7 Days Valididty":
        daysToAdd = 7;
        break;
      case "15 Days Valididty":
        daysToAdd = 15;
        break;
      case "30 Days Valididty":
        daysToAdd = 30;
        break;
      case "45 Days Valididty":
        daysToAdd = 45;
        break;
      default:
        daysToAdd = 0;
    }
    return dayjs().add(daysToAdd, "days");
  };

  const returnDocumentTypeTitle = () => {
    switch (documentType) {
      case AppConstants.DOCUMENT_TYPE.SALES_ENQUIRY:
        return "Sales enquiry";
      case AppConstants.DOCUMENT_TYPE.SALES_QUOTATION:
        return "Sales quotation";
      case AppConstants.DOCUMENT_TYPE.ORDER_CONFIRMATION:
        return "Order confirmation";
      case AppConstants.DOCUMENT_TYPE.DELIVERY_CHALLAN:
        return "Delivery challan";
      case AppConstants.DOCUMENT_TYPE.INVOICE:
        return "Invoice";
      case AppConstants.DOCUMENT_TYPE.SALES_RETURN:
        return "Sales return";
    }
  };

  const renderQuotationNumber = () => {
    const filteredDocuments = documentsData?.filter((document: any) => document.documentType === "Sales Quotation") || [];

    const data =
      filteredDocuments.map((document: any) => ({
        value: document.documentNumber,
        label: document.documentNumber,
      })) || [];

    const label = "Quotation Number";

    return (
      <Col className="gutter-row" span={6}>
        <label>{label}</label>
        <AutoComplete
          className="documentMetaDataFormFields"
          placeholder={`Please select or enter ${label.toLowerCase()}`}
          value={inputSeriesNumber}
          options={data?.map((doc: { value: any; label: any; }) => ({
            value: doc.value,
            label: doc.label,
          }))}
          onChange={(value: string) => {
            const selectedDocument = data?.find((doc: DocumentData) => doc.value === value);
            console.log(selectedDocument)

            setInputSeriesNumber(value);
            setDocumentMetaData({
              ...documentMetaData,
              enquiryNumber: value,
            });
            setDocumentItemForm({
              ...documentMetaData,
              enquiryNumber: value,
            });
          }}
        />
      </Col>
    );
  };

  const renderQuotationDate = () => {
    return (
      <>
        <Col className="gutter-row" span={6}>
          <div>
            <label>Quotation Date</label>
            <DatePicker
              className="documentMetaDataFormFields"
              onChange={(option) => {
                setDocumentMetaData({
                  ...documentMetaData,
                  quotationDate: option,
                });
                setDocumentItemForm({
                  ...documentMetaData,
                  quotationDate: option,
                });
              }}
            />
          </div>
        </Col>
      </>
    );
  };

  const renderEnquiryNumber = () => {
    const filteredDocuments = documentsData?.filter((document: any) => document.documentType === "Sales Enquiry") || [];
    const data =
      filteredDocuments.map((document: any) => ({
        value: document.documentNumber,
        label: document.documentNumber,
      })) || [];
    const label = "Enquiry Number";
    const salesEnquiryDocuments = documentsData?.filter((doc: { documentNumber: any }) => doc.documentNumber === "SE-2024-5009")
      .map((doc: { additionalDetails: any }) => doc.additionalDetails);
    return (
      <Col className="gutter-row" span={6}>
        <label>{label}</label>
        <AutoComplete
          className="documentMetaDataFormFields"
          placeholder={`Please select or enter ${label.toLowerCase()}`}
          value={inputSeriesNumber}
          options={data?.map((doc: { value: any; label: any; }) => ({
            value: doc.value,
            label: doc.label,
          }))}
          onChange={(value: string) => {
            const selectedDocument = data.find((doc: DocumentData) => doc.value === value);

            const enquiryDate = selectedDocument?.enquiryDate || null;
            const deliveryDate = selectedDocument?.deliveryDate || null;
            const store = selectedDocument.store || null;

            setInputSeriesNumber(value);
            setDocumentMetaData({
              ...documentMetaData,
              enquiryNumber: value,
              enquiryDate:
                documentType === AppConstants.DOCUMENT_TYPE.SALES_QUOTATION
                  ? enquiryDate
                  : documentMetaData.enquiryDate,
              deliveryDate: deliveryDate || documentMetaData.deliveryDate,
              store: store || documentMetaData.store,
            });

            setDocumentItemForm({
              ...documentMetaData,
              enquiryNumber: value,
              enquiryDate:
                documentType === AppConstants.DOCUMENT_TYPE.SALES_QUOTATION
                  ? enquiryDate
                  : documentMetaData.enquiryDate,
              deliveryDate: deliveryDate || documentMetaData.deliveryDate,
              store: store || documentMetaData.store,
            });
            console.log(value);
            console.log(filteredDocuments);
            let filterByDocumentNumber =  filteredDocuments.filter((doc: { documentNumber: any; }) => doc.documentNumber === value);
            console.log(filterByDocumentNumber[0]);
            setDocumentItemGridData(filterByDocumentNumber[0].items);
            setDocumentTermsAndConditionsData([filterByDocumentNumber[0].termsCondition]),
            setLogisticDetailsData([filterByDocumentNumber[0].logisticDetails]);
            setAdditionalDetailsData([filterByDocumentNumber[0].logisticDetails]);
            setDocumentAdditionalChargesData(filterByDocumentNumber[0].additionalCharges);
          }}
        />
      </Col>
    );
  };

  const renderEnquiryDate = () => {
    const label = 'Enquiry Date';

    return (
      <Col className="gutter-row" span={6}>
        <label>{label}</label>
        <DatePicker
          className="documentMetaDataFormFields"
          value={documentMetaData.enquiryDate ? dayjs(documentMetaData.enquiryDate) : null}
          onChange={(date) => {
            setDocumentMetaData({
              ...documentMetaData,
              enquiryDate: date,
            });
            setDocumentItemForm({
              ...documentMetaData,
              enquiryDate: date,
            });
          }}
        />
      </Col>
    );
  };

  const renderDocumentNumber = () => {
    return (
      <>
        <Col className="gutter-row" span={6}>
          <div className="flexBox">
            <label>{returnDocumentTypeTitle()} number *</label>
            <span className="expoText" onClick={() => setIsVisible(true)}>
              Customize
            </span>
          </div>
          <Input placeholder={`Please enter ${returnDocumentTypeTitle()?.toLocaleLowerCase()} number`} value={documentNumber} disabled={true} style={{ marginTop: "5px" }}></Input>
          {(docValidData && !docValidData[1]) && (
            <span className="errorText">* Document Number is required</span>
          )}
        </Col>
      </>
    );
  };

  const renderDocumentDate = () => {
    return (
      <>
        <Col className="gutter-row" span={6}>
          <label>Document Date *</label>
          <DatePicker
            className="documentMetaDataFormFields"
            disabled
            defaultValue={dayjs()}
            onChange={() => {
              const todayDate = dayjs();
              setDocumentMetaData({
                ...documentMetaData,
                documentDate: todayDate,
              });
              setDocumentItemForm({
                ...documentMetaData,
                documentDate: todayDate,
              });
            }}
          />
          {(docValidData && !docValidData[2]) && (
            <span className="errorText">* Document Date is required</span>
          )}
        </Col>
      </>
    );
  };

  const renderExpectedDeliveryDate = () => {
    return (
      <>
        <Col className="gutter-row" span={6}>
          <div>
            <label>Expected Delivery Date</label>
            <DatePicker
              className="documentMetaDataFormFields"
              value={documentMetaData.deliveryDate ? dayjs(documentMetaData.deliveryDate) : null}
              onChange={(date) => {
                console.log("Selected delivery date:", date);
                setDocumentMetaData({
                  ...documentMetaData,
                  deliveryDate: date,
                });
                setDocumentItemForm({
                  ...documentMetaData,
                  deliveryDate: date,
                });
              }}
            />
          </div>
        </Col>
      </>
    );
  };

  const renderStore = () => {
    const handleStoreChange = (option: any) => {
      setDocumentMetaData({ ...documentMetaData, store: option });
      setDocumentItemForm({ ...documentMetaData, store: option });

      // if (option) {
      //   setDocValidData((prevState: any) => ({
      //     ...prevState,
      //     [3]: true,  
      //   }));
      // }
    };

    return (
      <>
        <Col className="gutter-row" span={6}>
          <label>Store *</label>
          <Select
            className="documentMetaDataFormFields"
            showSearch
            placeholder="Select store"
            options={storeDropdownData}
            onChange={handleStoreChange} // Use the handler function here
          />
          {(docValidData && !docValidData[3]) && (
            <span className="errorText">* Store is required</span>
          )}
        </Col>
      </>
    );
  };

  const renderAmmendment = () => {
    return (
      <>
        <Col className="gutter-row" span={6}>
          <label>Ammendment</label>
          <Input
            placeholder='0'
            className="documentMetaDataFormFields"
            onBlur={(ev: any) => {
              setDocumentMetaData({
                ...documentMetaData,
                ammendment: ev.target.value,
              });
              setDocumentItemForm({
                ...documentMetaData,
                ammendment: ev.target.value,
              });
            }}
            // disabled
            readOnly
          />
        </Col>
      </>
    );
  };

  const renderPaymentTerm = () => {
    return (
      <>
        <Col className="gutter-row" span={6}>
          <label>Payment Term</label>
          <Select
            className="documentMetaDataFormFields"
            showSearch
            placeholder="Select Payment Term"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={[
              { value: "7 Days Valididty", label: "7 Days Valididty" },
              { value: "15 Days Valididty", label: "15 Days Valididty" },
              { value: "30 Days Valididty", label: "30 Days Valididty" },
              { value: "45 Days Valididty", label: "45 Days Valididty" },
            ]}
            onChange={(option: any) => {
              const calculatedDate = calculateExpectedPaymentDate(option);
              setDocumentMetaData({
                ...documentMetaData,
                paymentTerm: option,
                expectedPaymentDate: calculatedDate,
              });
              setDocumentItemForm({
                ...documentMetaData,
                paymentTerm: option,
                expectedPaymentDate: calculatedDate,
              });
            }}
          />
        </Col>
      </>
    );
  };

  const renderExpectedPaymentDate = () => {
    return (
      <>
        <Col className="gutter-row" span={6}>
          <div>
            <label>Expected Payment Date</label>
            <DatePicker
              className="documentMetaDataFormFields"
              value={documentMetaData.expectedPaymentDate}
              onChange={(option) => {
                setDocumentMetaData({
                  ...documentMetaData,
                  expectedPaymentDate: option,
                });
                setDocumentItemForm({
                  ...documentMetaData,
                  expectedPaymentDate: option,
                });
              }}
            />
          </div>
        </Col>
      </>
    );
  };

  const renderBillDate = () => {
    return (
      <>
        <Col className="gutter-row" span={6}>
          <div>
            <label>Expected Bill Date *</label>
            <DatePicker
              className="documentMetaDataFormFields"
              onChange={(option) => {
                setDocumentMetaData({
                  ...documentMetaData,
                  billDate: option,
                });
                setDocumentItemForm({
                  ...documentMetaData,
                  billDate: option,
                });
              }}
            />
          </div>
          {(docValidData && !docValidData[4]) && (
            <span className="errorText">* Bill Date is required</span>
          )}
        </Col>
      </>
    );
  };

  const renderPOC = () => {
    return (
      <>
        <Col className="gutter-row" span={6}>
          <label>POC Name</label>
          <Input
            className="documentMetaDataFormFields"
            placeholder="Please enter POC Name"
            onBlur={(ev: any) => {
              setDocumentMetaData({
                ...documentMetaData,
                POCName: ev.target.value,
              });
              setDocumentItemForm({
                ...documentMetaData,
                POCName: ev.target.value,
              });
            }}
          />
        </Col>
      </>
    );
  };

  const renderPOCNumber = () => {
    return (
      <>
        <Col className="gutter-row" span={6}>
          <label>POC Number</label>
          <Input
            className="documentMetaDataFormFields"
            placeholder="Please enter POC Number"
            onBlur={(ev: any) => {
              setDocumentMetaData({
                ...documentMetaData,
                POCNumber: ev.target.value,
              });
              setDocumentItemForm({
                ...documentMetaData,
                POCNumber: ev.target.value,
              });
            }}
          />
        </Col>
      </>
    );
  };

  const renderExpectedReplyDate = () => {
    return (
      <>
        <Col className="gutter-row" span={6}>
          <div>
            <label>Expected Reply Date</label>
            <DatePicker
              className="documentMetaDataFormFields"
              onChange={(option) => {
                setDocumentMetaData({
                  ...documentMetaData,
                  expectedReplyDate: option,
                });
                setDocumentItemForm({
                  ...documentMetaData,
                  expectedReplyDate: option,
                });
              }}
            />
          </div>
        </Col>
      </>
    );
  };

  const renderKindAttention = () => {
    return (
      <>
        <Col className="gutter-row" span={6}>
          <label>Kind Attention</label>
          <Input
            className="documentMetaDataFormFields"
            placeholder="Kind Attention..."
            onBlur={(ev: any) => {
              setDocumentMetaData({
                ...documentMetaData,
                kindAttention: ev.target.value,
              });
              setDocumentItemForm({
                ...documentMetaData,
                kindAttention: ev.target.value,
              });
            }}
          />
        </Col>
      </>
    );
  };

  const renderPurchaseOrderNumber = () => {
    return (
      <>
        <Col className="gutter-row" span={6}>
          <label>Purchase Order Number</label>
          <Input
            className="documentMetaDataFormFields"
            placeholder="Please enter purchase order number"
            onBlur={(ev: any) => {
              setDocumentMetaData({
                ...documentMetaData,
                purchaseOrderNumber: ev.target.value,
              });
              setDocumentItemForm({
                ...documentMetaData,
                purchaseOrderNumber: ev.target.value,
              });
            }}
          />
        </Col>
      </>
    );
  };

  const renderPurchaseOrderDate = () => {
    return (
      <>
        <Col className="gutter-row" span={6}>
          <div>
            <label>Purchase Order Date</label>
            <DatePicker
              className="documentMetaDataFormFields"
              onChange={(option) => {
                setDocumentMetaData({
                  ...documentMetaData,
                  purchaseOrderDate: option,
                });
                setDocumentItemForm({
                  ...documentMetaData,
                  purchaseOrderDate: option,
                });
              }}
            />
          </div>
        </Col>
      </>
    );
  };

  const renderOrderConfirmationNumber = () => {
    const filteredDocuments = documentsData?.filter((document: any) => document.documentType === "Order Confirmation") || [];
    console.log(filteredDocuments)

    const data =
      filteredDocuments.map((document: any) => ({
        value: document.documentNumber,
        label: document.documentNumber,
      })) || [];
    console.log(data);

    return (
      <>
        <Col className="gutter-row" span={6}>
          <label>Order Confirmation Number</label>
          <AutoComplete
            className="documentMetaDataFormFields"
            placeholder="Please enter order confirmation number"
            value={inputSeriesNumber}
            options={data?.map((doc: { value: any; label: any }) => ({
              value: doc.value,
              label: doc.label,
            }))}
            onChange={(value: string) => {
              const selectedDocument = data.find(
                (doc: DocumentData) => doc.value === value
              );
              console.log(selectedDocument);

              setInputSeriesNumber(value);
              setDocumentMetaData({
                ...documentMetaData,
                enquiryNumber: value,
              });
              setDocumentItemForm({
                ...documentMetaData,
                enquiryNumber: value,
              });
            }}
          />
        </Col>
      </>
    );
  };

  const renderOrderConfirmationDate = () => {
    return (
      <>
        <Col className="gutter-row" span={6}>
          <div>
            <label>Order Confirmation Date</label>
            <DatePicker
              className="documentMetaDataFormFields"
              onChange={(option) => {
                setDocumentMetaData({
                  ...documentMetaData,
                  orderConfirmationDate: option,
                });
                setDocumentItemForm({
                  ...documentMetaData,
                  orderConfirmationDate: option,
                });
              }}
            />
          </div>
        </Col>
      </>
    );
  };

  const renderTransporterName = () => {
    return (
      <>
        <Col className="gutter-row" span={6}>
          <label>Transporter Name</label>
          <Input
            className="documentMetaDataFormFields"
            placeholder="Please enter transporter name"
            onBlur={(ev: any) => {
              setDocumentMetaData({
                ...documentMetaData,
                transporterName: ev.target.value,
              });
              setDocumentItemForm({
                ...documentMetaData,
                transporterName: ev.target.value,
              });
            }}
          />
        </Col>
      </>
    );
  };

  const renderTransporterGSTNumber = () => {
    return (
      <>
        <Col className="gutter-row" span={6}>
          <label>Transporter GST Number</label>
          <Input
            className="documentMetaDataFormFields"
            placeholder="Please enter transporter GST number"
            onBlur={(ev: any) => {
              setDocumentMetaData({
                ...documentMetaData,
                transporterGSTNumber: ev.target.value,
              });
              setDocumentItemForm({
                ...documentMetaData,
                transporterGSTNumber: ev.target.value,
              });
            }}
          />
        </Col>
      </>
    );
  };

  const renderTransportationDocumentNumber = () => {
    return (
      <>
        <Col className="gutter-row" span={6}>
          <label>Transportation Document Number</label>
          <Input
            className="documentMetaDataFormFields"
            placeholder="Please enter transportation document number"
            onBlur={(ev: any) => {
              setDocumentMetaData({
                ...documentMetaData,
                transportationDocumentNumber: ev.target.value,
              });
              setDocumentItemForm({
                ...documentMetaData,
                transportationDocumentNumber: ev.target.value,
              });
            }}
          />
        </Col>
      </>
    );
  };

  const renderVehicleNumber = () => {
    return (
      <>
        <Col className="gutter-row" span={6}>
          <label>Vehicle Number</label>
          <Input
            className="documentMetaDataFormFields"
            placeholder="Please enter vehicle number"
            onBlur={(ev: any) => {
              setDocumentMetaData({
                ...documentMetaData,
                vehicleNumber: ev.target.value,
              });
              setDocumentItemForm({
                ...documentMetaData,
                vehicleNumber: ev.target.value,
              });
            }}
          />
        </Col>
      </>
    );
  };

  const renderTransportationDocumentDate = () => {
    return (
      <>
        <Col className="gutter-row" span={6}>
          <div>
            <label>Transportation Document Date</label>
            <DatePicker
              className="documentMetaDataFormFields"
              onChange={(option) => {
                setDocumentMetaData({
                  ...documentMetaData,
                  transportationDocumentDate: option,
                });
                setDocumentItemForm({
                  ...documentMetaData,
                  transportationDocumentDate: option,
                });
              }}
            />
          </div>
        </Col>
      </>
    );
  };

  const renderInvoiceNumber = () => {
    return (
      <>
        <Col className="gutter-row" span={6}>
          <label>Invoice Number *</label>
          <Input
            className="documentMetaDataFormFields"
            placeholder="Please enter invoice number"
            onBlur={(ev: any) => {
              setDocumentMetaData({
                ...documentMetaData,
                invoiceNumber: ev.target.value,
              });
              setDocumentItemForm({
                ...documentMetaData,
                invoiceNumber: ev.target.value,
              });
            }}
          />
          {(docValidData && !docValidData[5]) && (
            <span className="errorText">* Invoice Number is required</span>
          )}
        </Col>
      </>
    );
  };

  const renderInvoiceDate = () => {
    return (
      <>
        <Col className="gutter-row" span={6}>
          <div>
            <label>Invoice Date</label>
            <DatePicker
              className="documentMetaDataFormFields"
              onChange={(option) => {
                setDocumentMetaData({
                  ...documentMetaData,
                  invoiceDate: option,
                });
                setDocumentItemForm({
                  ...documentMetaData,
                  invoiceDate: option,
                });
              }}
            />
          </div>
        </Col>
      </>
    );
  };

  const renderReturnRecievedDate = () => {
    return (
      <>
        <Col className="gutter-row" span={6}>
          <div>
            <label>Return Recieved Date</label>
            <DatePicker
              className="documentMetaDataFormFields"
              onChange={(option) => {
                setDocumentMetaData({
                  ...documentMetaData,
                  recievedDate: option,
                });
                setDocumentItemForm({
                  ...documentMetaData,
                  recievedDate: option,
                });
              }}
            />
          </div>
        </Col>
      </>
    );
  };

  const renderCreditNoteNumber = () => {
    return (
      <>
        <Col className="gutter-row" span={6}>
          <label>Credit Note Number</label>
          <Input
            className="documentMetaDataFormFields"
            placeholder="Please enter credit note number"
            onBlur={(ev: any) => {
              setDocumentMetaData({
                ...documentMetaData,
                creditNoteNumber: ev.target.value,
              });
              setDocumentItemForm({
                ...documentMetaData,
                creditNoteNumber: ev.target.value,
              });
            }}
          />
        </Col>
      </>
    );
  };

  const renderCreditNoteDate = () => {
    return (
      <>
        <Col className="gutter-row" span={6}>
          <div>
            <label>Credit Note Date</label>
            <DatePicker
              className="documentMetaDataFormFields"
              onChange={(option) => {
                setDocumentMetaData({
                  ...documentMetaData,
                  creditNoteDate: option,
                });
                setDocumentItemForm({
                  ...documentMetaData,
                  creditNoteDate: option,
                });
              }}
            />
          </div>
        </Col>
      </>
    );
  };

  return (
    <>
      <div className="documentMetaDataForm">
        {documentType === AppConstants.DOCUMENT_TYPE.SALES_ENQUIRY && (
          <>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-20">
              {renderDocumentNumber()}
              {renderDocumentDate()}
              {renderAmmendment()}
              {renderExpectedDeliveryDate()}
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-20">
              {renderPaymentTerm()}
              {renderStore()}
              {renderEnquiryDate()}
              {renderPOC()}
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-20">
              {renderPOCNumber()}
              {renderExpectedReplyDate()}
              {renderKindAttention()}
            </Row>
          </>
        )}

        {documentType === AppConstants.DOCUMENT_TYPE.SALES_QUOTATION && (
          <>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-20">
              {renderDocumentNumber()}
              {renderDocumentDate()}
              {renderEnquiryNumber()}
              {renderEnquiryDate()}
            </Row>

            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-20">
              {renderExpectedDeliveryDate()}
              {renderStore()}
              {renderPaymentTerm()}
              {renderExpectedPaymentDate()}
            </Row>

            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-20">
              {renderAmmendment()}
            </Row>
          </>
        )}

        {documentType === AppConstants.DOCUMENT_TYPE.ORDER_CONFIRMATION && (
          <>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-20">
              {renderDocumentNumber()}
              {renderDocumentDate()}
              {renderQuotationNumber()}
              {renderQuotationDate()}
            </Row>

            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-20">
              {renderExpectedDeliveryDate()}
              {renderStore()}
              {renderPaymentTerm()}
              {renderExpectedPaymentDate()}
            </Row>

            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-20">
              {renderAmmendment()}
              {renderPurchaseOrderNumber()}
              {renderPurchaseOrderDate()}
            </Row>
          </>
        )}

        {documentType === AppConstants.DOCUMENT_TYPE.DELIVERY_CHALLAN && (
          <>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-20">
              {renderDocumentNumber()}
              {renderDocumentDate()}
              {renderPurchaseOrderNumber()}
              {renderPurchaseOrderDate()}
            </Row>

            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-20">
              {renderOrderConfirmationNumber()}
              {renderOrderConfirmationDate()}
              {renderStore()}
              {renderTransporterName()}
            </Row>

            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-20">
              {renderTransporterGSTNumber()}
              {renderTransportationDocumentNumber()}
              {renderVehicleNumber()}
              {renderTransportationDocumentDate()}
            </Row>
          </>
        )}

        {documentType === AppConstants.DOCUMENT_TYPE.INVOICE && (
          <>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-20">
              {renderDocumentNumber()}
              {renderDocumentDate()}
              {renderEnquiryNumber()}
              {renderEnquiryDate()}
            </Row>

            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-20">
              {renderExpectedDeliveryDate()}
              {renderStore()}
              {renderPaymentTerm()}
              {renderExpectedPaymentDate()}
            </Row>

            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-20">
              {renderAmmendment()}
              {renderPurchaseOrderNumber()}
              {renderPurchaseOrderDate()}
              {renderBillDate()}
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-20">

            </Row>
          </>
        )}

        {documentType === AppConstants.DOCUMENT_TYPE.SALES_RETURN && (
          <>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-20">
              {renderDocumentNumber()}
              {renderDocumentDate()}
              {/* {renderAmmendment()} */}
              {renderTransportationDocumentDate()}
              {renderReturnRecievedDate()}
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-20">
              {renderPurchaseOrderNumber()}
              {renderPurchaseOrderDate()}
              {renderOrderConfirmationNumber()}
              {renderOrderConfirmationDate()}
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-20">
              {renderInvoiceNumber()}
              {renderInvoiceDate()}
              {renderCreditNoteNumber()}
              {renderCreditNoteDate()}
            </Row>

            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-20">
              {renderStore()}
              {renderTransporterName()}
              {renderTransportationDocumentNumber()}
              {renderVehicleNumber()}
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="mt-20">

            </Row>
          </>
        )}
      </div>

      <SeriesMetaDataModal
        isVisible={isVisible}
        onClose={handleSeriesModalClose}
        onSelectSeries={handleSeriesSelection}
        setIsVisible={setIsVisible}
      />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  storesData: state.api.getStore?.data?.reverse(),
  seriesData: state.api.getDocumentSeries?.data?.reverse(),
  documentsData: state.api.getDocuments?.data?.reverse(),
  itemsData: state.api.getItems?.data?.reverse(),
  userId: state.api.login.data.id,
  companyId: state.api.login.data.companyId,
  dataSource: state.ui.createDocumentData.documentItemsGridData?.documentItemFormData,
  documentType: state.ui.createDocumentData.documentType,
  documentCompanyFormsData: state.ui.createDocumentData?.documentItemFormData,
});

const mapDispatchToProps = (dispatch: any) => ({
  setDocumentItemForm: (payload: any) => dispatch(setDocumentItemFormData(payload)),
  getStores: (payload: any) => dispatch(getStores(payload)),
  getDocumentSeries: (payload: any) => dispatch(getDocumentSeries(payload)),
  getDocuments: (payload: any) => dispatch(getDocuments(payload)),
  setDocumentItemGridData: (payload: any) => dispatch(setDocumentItemGridData(payload)),
  setDocumentTermsAndConditionsData: (payload: any) => dispatch(setDocumentTermsAndConditionsData(payload)),
  setLogisticDetailsData: (payload: any) => dispatch(setLogisticDetailsData(payload)),
  setAdditionalDetailsData: (payload: any) => dispatch(setAdditionalDetailsData(payload)),
  setDocumentAdditionalChargesData: (payload: any) => dispatch(setDocumentAdditionalChargesData(payload))
});
export default connect(mapStateToProps, mapDispatchToProps)(DocumentMetaDataForm);
