import React, { Component } from 'react';
import { Button, Input, Form, Row, Col, Popconfirm, Space, Table, Tag, Drawer, Select, notification } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { SeriesData, ProfileSeriesMetaDataModalProps, SeriesMetaDataModalState, } from "./IProfileSeries";
import "../Components/SeriesMetaData/SeriesMetaDataModal.css";
import { addDocumentSeries, deleteDocumentSeries, editDocumentSeries, getDocumentSeries } from '../../redux/actions/API/documentSeriesAction';
import { connect } from 'react-redux';
import noData from '../../Utility/images/noData.png'

type NotificationType = "success" | "info" | "warning" | "error";

const { Option } = Select;

class SeriesMetaDataModal extends Component<ProfileSeriesMetaDataModalProps, SeriesMetaDataModalState> {
    formRef: any;

    constructor(props: ProfileSeriesMetaDataModalProps) {
        super(props);
        this.state = {
            seriesName: "",
            series: "",
            number: undefined,
            docType: "",
            dataSource: this.props.documentSeriesData,
            activeKey: null,
            isFormVisible: false,
            isEditing: false,
            editRecord: null,
            ip_address: "",
            loading: true
        };

        this.formRef = React.createRef();
    }

    componentDidMount(): void {
        // this.props.getDocumentSeries({ companyId: Number(this.props.companyId) });
    }

    componentDidUpdate(
        prevProps: Readonly<ProfileSeriesMetaDataModalProps>,
        prevState: Readonly<SeriesMetaDataModalState>,
        snapshot?: any
    ): void {
        if (
            prevProps.getDocumentSeriesState?.loading &&
            !this.props.getDocumentSeriesState?.loading
        ) {
            if (this.props.getDocumentSeriesState?.error?.length > 0) {
                this.openNotificationWithIcon("error", "Failed to add address");
            } else {
                this.setState({
                    loading: false,
                    dataSource: this.props.documentSeriesData
                });
            }
        }

        if (
            prevProps.addDocumentSeriesState?.loading &&
            !this.props.addDocumentSeriesState?.loading
        ) {
            if (this.props.addDocumentSeriesState?.error?.length > 0) {
                this.openNotificationWithIcon("error", "Failed to add address");
            } else {
                this.props.getDocumentSeries({
                    companyId: Number(this.props.companyId),
                });
                this.openNotificationWithIcon("success", "Series added successfully");
                this.setState({
                    dataSource: this.props.documentSeriesData,
                });
            }
        }

        if (
            prevProps.editDocumentSeriesState?.loading &&
            !this.props.editDocumentSeriesState?.loading
        ) {
            if (this.props.editDocumentSeriesState?.error?.length > 0) {
                this.openNotificationWithIcon("error", "Failed to update address");
            } else {
                this.props.getDocumentSeries({
                    companyId: Number(this.props.companyId),
                });
                this.openNotificationWithIcon(
                    "success",
                    "Series updated successfully"
                );
                this.setState({
                    dataSource: this.props.documentSeriesData,
                });
            }
        }

        if (
            prevProps.deleteDocumentSeriesState?.loading &&
            !this.props.deleteDocumentSeriesState?.loading
        ) {
            if (this.props.deleteDocumentSeriesState?.error?.length > 0) {
                this.openNotificationWithIcon("error", "Failed to delete address");
            } else {
                this.props.getDocumentSeries({
                    companyId: Number(this.props.companyId),
                });
                this.openNotificationWithIcon(
                    "success",
                    "Address deleted successfully"
                );
                this.setState({
                    dataSource: this.props.documentSeriesData,
                });
            }
        }
        if (prevProps.documentSeriesData !== this.props.documentSeriesData) {
            this.setState({
                // filteredData: this.props.documentSeriesData,
            });
        }
    }

    openNotificationWithIcon = (type: NotificationType, message: string) => {
        notification[type]({
            message: message,
            duration: 2,
        });
    };

    handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(e.target.value, 10);
        if (!isNaN(value)) {
            this.setState({ number: value });
        }
    };

    handleSubmit = () => {
        this.formRef.current.validateFields().then((values: any) => {
            const { seriesName, series, number, docType, } = values;
            const { dataSource, isEditing, editRecord } = this.state;
            const documentNumber = `${series}${number}`;
            if (isEditing && editRecord) {
                this.props.editDocumentSeries({
                    id: editRecord.id,
                    seriesName: seriesName,
                    series: series,
                    number: number,
                    nextNumber: number,
                    default: dataSource.length > 0 ? 0 : 1,
                    docType: docType,
                    companyId: Number(this.props.companyId),
                    userId: Number(this.props.userId),
                    ip_address: '192.168.1.1',
                    isActive: editRecord.isActive,
                    documentNumber: documentNumber
                });

                this.setState({
                    isEditing: false,
                    editRecord: null,
                    isFormVisible: false,
                });
            } else {
                this.props.addDocumentSeries({
                    seriesName: seriesName,
                    series: series,
                    number: number,
                    nextNumber: number,
                    default: dataSource.length > 0 ? 0 : 1,
                    docType: docType,
                    companyId: Number(this.props.companyId),
                    userId: Number(this.props.userId),
                    ip_address: '127.0.0.1'
                });

                this.setState(prevState => ({
                    dataSource: [...prevState.dataSource.map(series => ({ ...series, isActive: false }))],
                    isFormVisible: false,
                }));
                const documentNumbers = [documentNumber, ...dataSource.map((series) => series.documentNumber)];
                this.formRef.current.resetFields();
            }
        }).catch((errorInfo: any) => {
        });
    };

    handleAddNewSeries = () => {
        this.setState({ isFormVisible: true, isEditing: false });
    };

    handleCancel = () => {
        this.setState({ isFormVisible: false });
        this.formRef.current.resetFields();
    };

    handleEditSeries = (record: SeriesData) => {
        this.setState(
            {
                isEditing: true,
                editRecord: record,
                isFormVisible: true,
            },
            () => {
                if (this.formRef.current) {
                    this.formRef.current.setFieldsValue({
                        seriesName: record.seriesName,
                        series: record.prefix,
                        number: record.number,
                        docType: record.DocType,
                    });
                }
            }
        );
    };

    handleCheckboxChange = (key: string, documentNumber: string) => {
        const updatedDataSource = this.state.dataSource.map((series) => ({
            ...series,
            isActive: series.key === key,
        }));
        this.setState({ activeKey: key, dataSource: updatedDataSource });
        // this.props.onSelectSeries(documentNumber);
    };

    handleDelete = (id: number) => {
        this.setState({
            loading: true,
        });
        this.props.deleteDocumentSeries({
            id: id,
        });
    };

    formatData = (dataSource: any) => {
        let data = this.state.dataSource?.map((elem: any) => {
            return {
                DocType: elem.DocType,
                seriesName: elem.seriesName,
                prefix: elem.prefix,
                number: elem.number,
                numberData: elem.prefix + '-' + elem.number,
                nextNumber: elem.nextNumber,
                nextNumberData: elem.prefix + '-' + elem.nextNumber,
                default: elem.default,
                defaultData: elem.default == 1 ? <Tag color="green">Default</Tag> : <Tag color="blue" style={{ cursor: "pointer" }}>Set as default</Tag>
            }
        });
        return data;
    }

    render() {
        const { docType, seriesName, series, number, dataSource, activeKey, isFormVisible, isEditing } = this.state;

        const columns = [
            {
                title: 'Document Type',
                dataIndex: 'DocType',
                key: 'docType'
            },
            {
                title: 'Name',
                dataIndex: 'seriesName',
                key: 'seriesName'
            },
            {
                title: 'Prefix',
                dataIndex: 'prefix',
                key: 'series'
            },
            {
                title: 'Doc. Starting No.',
                dataIndex: 'numberData',
                key: 'number',
            },
            {
                title: 'Doc. Next No.',
                dataIndex: 'nextNumberData',
                key: 'nextNumber'
            },
            {
                title: 'Default',
                dataIndex: 'defaultData',
                key: 'defaultData',
            },
            {
                title: 'Action',
                key: 'action',
                render: (_: any, record: any) => (
                    <Space size="middle">
                        <a onClick={() => this.handleEditSeries(record)}><EditOutlined /></a>
                        <Popconfirm
                            title="Are you sure to delete this?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => this.handleDelete(record.id)}
                        >
                            <a key="list-loadmore-more"><DeleteOutlined /></a>
                        </Popconfirm>
                    </Space>
                ),
            },
        ];

        return (
            <div>
                <div className="modal-header-profile">
                    <p>Customize document number</p>
                    <Button className='button-add-series' type="link" onClick={this.handleAddNewSeries}>Add New Series</Button>
                </div>

                {dataSource.length > 0 ? <Table
                    bordered
                    dataSource={this.formatData(dataSource)}
                    columns={columns}
                    pagination={false}
                    locale={{ emptyText: 'No data available' }}
                /> : <div className="center">
                    <img src={noData} alt='bank details'/>
                    <div>No records available!</div>
                </div>}

                <Drawer
                    title={isEditing ? "Edit Custom Document Number Series" : "Create Custom Document Number Series"}
                    width={720}
                    onClose={this.handleCancel}
                    visible={isFormVisible}
                    bodyStyle={{ paddingBottom: 80 }}
                    extra={
                        <Space>
                            <div style={{ textAlign: 'right' }}>
                                <Button onClick={this.handleCancel} style={{ marginRight: 8 }}>Cancel</Button>
                                <Button onClick={() => this.handleSubmit()} type="primary">
                                    {isEditing ? 'Update' : 'Submit'}
                                </Button>
                            </div>
                        </Space>
                    }
                >
                    <Form ref={this.formRef} layout="vertical" requiredMark className="series-form">
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Document Type" name="docType" rules={[{ required: true, message: "Please select a document type" }]}>
                                    <Select placeholder="Select document type">
                                        <Option value="Sales Enquiry">Sales Enquiry</Option>
                                        <Option value="Sales Quotation">Sales Quotation</Option>
                                        <Option value="Order Confirmation">Order Confirmation</Option>
                                        <Option value="Delivery Challan">Delivery Challan</Option>
                                        <Option value="Invoice">Invoice</Option>
                                        <Option value="Sales Return">Sales Return</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Series Name" name="seriesName" rules={[{ required: true, message: "Please fill the series name" }]}>
                                    <Input value={seriesName} onChange={(e) => this.setState({ seriesName: e.target.value })} placeholder="Enter series name" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Prefix" name="series" rules={[{ required: true, message: "Please fill the prefix" }]}>
                                    <Input value={series} onChange={(e) => this.setState({ series: e.target.value })} placeholder="Enter prefix" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Number" name="number" rules={[{ required: true, message: "Please fill the number" }]}>
                                    <Input type="number" value={number} onChange={this.handleNumberChange} placeholder="Enter number" disabled={isEditing} />
                                </Form.Item>
                            </Col>
                        </Row>

                    </Form>
                </Drawer>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    documentSeriesData: state.api.getDocumentSeries?.data?.reverse(),
    userId: state.api.login.data.id,
    companyId: state.api.login.data.companyId,
    getDocumentSeriesState: state.api.getDocumentSeries,
    addDocumentSeriesState: state.api.addDocumentSeries,
    editDocumentSeriesState: state.api.editDocumentSeries,
    deleteDocumentSeriesState: state.api.deleteDocumentSeries,
});

const mapDispatchToProps = (dispatch: any) => ({
    addDocumentSeries: (payload: any) => dispatch(addDocumentSeries(payload)),
    getDocumentSeries: (payload: any) => dispatch(getDocumentSeries(payload)),
    editDocumentSeries: (payload: any) => dispatch(editDocumentSeries(payload)),
    deleteDocumentSeries: (payload: any) => dispatch(deleteDocumentSeries(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SeriesMetaDataModal);
