import {
  Avatar,
  Badge,
  Button,
  Dropdown,
  Layout,
  Popover,
  theme,
  Menu,
  notification,
  Input,
} from "antd";
import { Footer, Header } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import { MenuProps } from "antd/es/menu/menu";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../Utility/images/ease/transparent/ease-primary-white.png";
import {
  DashboardOutlined,
  LockOutlined,
  SettingOutlined,
  IdcardOutlined,
  UserSwitchOutlined,
  GoldOutlined,
  CommentOutlined,
  ShopOutlined,
  MenuOutlined,
  QuestionCircleOutlined,
  BellOutlined,
  CloseOutlined,
  UserOutlined,
} from "@ant-design/icons";
import BuyerSupplier from "./BuyerSupplier";
import Dashboard from "../../Dashboard/Components/Dashboard";
import Settings from "../../Dashboard/Components/Usermanagement";
import Usermanagement from "../../Dashboard/Components/Usermanagement";
import Teammanagement from "../../Dashboard/Components/teams/Teammanagement";
import ProfileManagement from "../../Dashboard/Components/ProfileManagement";
import ManageBlogs from "../../Dashboard/Components/ManageBlogs";
import { connect } from "react-redux";
import { Persistor } from "../../redux/store";
import "./Main.css";
import Permissions from "../../Dashboard/Components/Permissions";
import ItemManagement from "../../Dashboard/Components/ItemManagement";
import StoresManagement from "../../Dashboard/Components/StoresManagement";
import SalesQuotationView from "../../Dashboard/Components/previewDocument/PreviewDocument";
import CommentsCollections from "../../Dashboard/Components/blog-comments/CommentsCollections";
import Documents from "../../Dashboard/Components/Documents";
import CreateDocument from "../../Dashboard/Components/Documents/CreateDocument";
import PreviewDocument from "../../Dashboard/Components/previewDocument/PreviewDocument";
import { getNotifications } from "../../redux/actions/API/notifications";
import noNotification from '../../Utility/images/nonotification.png'

interface IMainInterface {
  loginData: any;
  notifications: any;
  getNotifications: Function;
}

const Main: React.FC<IMainInterface> = ({ loginData, notifications, getNotifications }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [editData, setEditData] = useState<any | null>(null);
  const [viewData, setViewData] = useState<any | null>(null);
  const [visible, setVisible] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    getNotifications({ companyId: loginData.data.companyId });
  }, [])

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const handleLogout = () => {
    Persistor.purge().then(() => {
      localStorage.removeItem("easemargin");
      console.log("Storage key 'easemargin' cleared.");
      window.location.href = "/sign-in";
    });
  };

  const menu = (
    <Menu className="menu-notification">
      <div className="notification-header">
        Notifications
      </div>

      <div className="notification-list-container">
        {notifications?.length > 0 ? (
          notifications?.map((notification: any) => (
            <Menu.Item key={notification.id}>
              <div className="item-notification">
                <span className="avatar-wrapper">
                  <Avatar style={{ backgroundColor: '#0167ff' }} icon={<UserOutlined />} />
                </span>
                <div className="notification-user-action">
                  <div className="notification-text">{notification.notification}</div>
                </div>
                <div className="dismiss-icon">
                  <CloseOutlined />
                </div>
              </div>
            </Menu.Item>
          ))
        ) : (
          <div className="noNotifications">
            <img src={noNotification} alt="No Notifications" />
          </div>
        )}
      </div>
    </Menu>

  );

  const handleMenuClick = (data: any) => {
    console.log(data);
    // setSiderViewVisble(true)
    navigate("/" + data.key);
  };

  const handleAdd = (newData: any) => {
    setData((prevData) => [...prevData, { ...newData, id: Date.now() }]);
  };

  const handleEdit = (updatedData: any) => {
    setData((prevData) =>
      prevData.map((item) =>
        item.id === editData?.id ? { ...item, ...updatedData } : item
      )
    );
  };

  const handleViewDetails = (item: any) => {
    console.log(item);
    setIsOpen(!isOpen);
    setViewData(item);
  };

  const handleDelete = (id: number) => {
    setData((prevData) => prevData.filter((item) => item.id !== id));
  };

  const handleEditClick = (item: any) => {
    setIsOpen(!isOpen);
    setEditData(item);
  };

  const renderComponents = (elem?: string) => {
    if (window.location.href.includes("buyer_supplier")) {
      return <BuyerSupplier />;
    }
    if (window.location.href.includes("dashboard") && checkAuth()) {
      console.log(window.location.href);
      return <Dashboard />;
    }
    if (window.location.href.includes("settings")) {
      console.log(window.location.href);
      return <Settings />;
    }
    if (window.location.href.includes("user_management")) {
      console.log(window.location.href);
      return <Usermanagement />;
    }
    if (window.location.href.includes("team_management")) {
      console.log(window.location.href);
      return <Teammanagement />;
    }
    if (window.location.href.includes("profile_management")) {
      console.log(window.location.href);
      return <ProfileManagement />;
    }
    if (window.location.href.includes("items")) {
      console.log(window.location.href);
      return <ItemManagement />;
    }
    if (window.location.href.includes("stores")) {
      console.log(window.location.href);
      return <StoresManagement />;
    }
    if (window.location.href.includes("manage_blogs")) {
      console.log(window.location.href);
      return <ManageBlogs />;
    }
    if (window.location.href.includes("comments")) {
      console.log(window.location.href);
      return <CommentsCollections />;
    }
    if (window.location.href.includes("permissions")) {
      console.log(window.location.href);
      return <Permissions />;
    }
    if (window.location.href.includes("create_document")) {
      console.log(window.location.href);
      return <CreateDocument />;
    }
    if (window.location.href.includes("previewDocument")) {
      console.log(window.location.href);
      return <PreviewDocument />;
    }
    if (window.location.href.includes("documents")) {
      console.log(window.location.href);
      return <Documents />;
    }
  };

  const checkAuth = () => {
    return loginData?.data?.token ? true : false;
  };

  const items: MenuProps["items"] = [
    {
      label: <a href="#">Profile</a>,
      key: "0",
    },
    {
      type: "divider",
    },
    {
      label: <span onClick={handleLogout}>Logout</span>,
      key: "2",
    },
  ];

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <div>
      <Layout className="mainLayout">
        <Header
          className="header"
          style={{
            alignItems: "center",
            position: "sticky",
          }}
        >
          <div>
            <MenuOutlined className="hamburgur hide" onClick={toggleNav} />
            <img
              src={logo}
              className="logo"
              alt="Ease Margin"
              style={{ margin: "0px 0px 0px -30px", height: "30px" }}
              onClick={() => {
                navigate("/dashboard");
              }}
            />
          </div>
          <div>
            <Input
              className="global-search-input"
              placeholder="Global Search. Ex: Item/Item name"
            />
          </div>
          <div>
            <QuestionCircleOutlined style={{ color: "#FFFFFF" }} />
            <Dropdown
              overlay={menu}
              trigger={["click"]}
              visible={visible}
              onVisibleChange={(flag) => setVisible(flag)}
            >
              <Badge count={notifications?.length} className="notificationCount">
                <BellOutlined
                  style={{
                    color: "#FFFFFF",
                    marginLeft: "20px",
                    fontSize: "20px",
                    cursor: "pointer",
                  }}
                />
              </Badge>
            </Dropdown>
            <Dropdown menu={{ items }} trigger={["click"]}>
              <a onClick={(e) => e.preventDefault()}>
                <UserOutlined
                  style={{
                    marginRight: "10px",
                    marginLeft: "20px",
                    color: "#FFFFFF",
                  }}
                />
                <span style={{ color: "#FFFFFF" }} className="dhide">
                  {loginData.data.email}
                </span>
              </a>
            </Dropdown>
          </div>
        </Header>
        <Layout>
          <Sider
            className={`sideNav ${isNavOpen ? "active" : ""}`}
            width={200}
            style={{
              background: colorBgContainer,
              minHeight: "100vh",
            }}
          >
            <Menu
              mode="inline"
              theme="light"
              defaultSelectedKeys={["1"]}
              style={{
                height: "100%",
                borderRight: 0,
              }}
              items={[
                {
                  key: "dashboard",
                  icon: <DashboardOutlined />,
                  label: "Dashboard",
                },
                {
                  key: "buyer_supplier",
                  icon: <UserOutlined />,
                  label: "Customers",
                },
                {
                  key: "inventory_management",
                  icon: <GoldOutlined />,
                  label: "Inventory",
                  children: [
                    {
                      key: "items",
                      icon: <UserSwitchOutlined />,
                      label: "Items",
                    },
                    {
                      key: "stores",
                      icon: <UserSwitchOutlined />,
                      label: "Stores",
                    },
                  ],
                },
                {
                  key: "sales",
                  icon: <ShopOutlined />,
                  label: "Sales",
                  children: [
                    {
                      key: "documents",
                      icon: <ShopOutlined />,
                      label: "Documents",
                    },
                  ],
                },
                {
                  key: "settings",
                  icon: <SettingOutlined />,
                  label: "Settings",
                  children: [
                    {
                      key: "profile_management",
                      icon: <IdcardOutlined />,
                      label: "Profile",
                    },
                    {
                      key: "user_management",
                      icon: <UserOutlined />,
                      label: "Users",
                    },
                    // {
                    //   key: "permissions",
                    //   icon: <LockOutlined />,
                    //   label: "Permissions",
                    // },
                  ],
                },
                { key: "manage_blogs", icon: <GoldOutlined />, label: "Blogs" },
                {
                  key: "comments",
                  icon: <CommentOutlined />,
                  label: "Comments",
                },
              ]}
              onClick={(elem: any) => {
                handleMenuClick(elem);
              }}
            />
          </Sider>
          {renderComponents()}
        </Layout>
        <Footer
          style={{
            textAlign: 'left', // Align content to the left
            position: 'sticky',
            bottom: 0,
            backgroundColor: '#f0f2f5', // Change to match your theme
          }}
        >
          &copy; {currentYear} All rights reserved. Designed and developed by Datronix Digital Pvt. Ltd.
        </Footer>
      </Layout>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  loginData: state.api.login,
  notifications: state.api.getNotifications?.data?.reverse()
});

const mapDispatchToProps = (dispatch: any) => ({
  getNotifications: (payload: any) => dispatch(getNotifications(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
