import React from "react";
import Production from '../../Utility/images/icon-production.png';
import inventory from '../../Utility/images/inventoryicon.png';
import sales from '../../Utility/images/salesmanagement.png';
import user from '../../Utility/images/usericon.png';
import './Features.css'

interface IFeaturesProps {
    
}
 
interface IFeaturesState {
    
}
 
class Features extends React.Component<IFeaturesProps, IFeaturesState> {
    constructor(props: IFeaturesProps) {
        super(props);
    }

	state = {
		isMobile: false,
	  };

	componentDidMount() {
		this.updateIsMobile();
		window.addEventListener('resize', this.updateIsMobile);
	  }
	
	  componentWillUnmount() {
		window.removeEventListener('resize', this.updateIsMobile);
	  }
	
	  updateIsMobile = () => {
		this.setState({ isMobile: window.innerWidth <= 767 });
	  };

    render() { 
		const { isMobile } = this.state;
        return ( <>
            <section id="features-13" className={`shape--bg pt-100 features-section division main-container ${
          !isMobile ? 'shape--purple-100' : ''
        }`}
        style={isMobile ? { backgroundColor: 'transparent' } : {}}>
				<div className="container">
					<div className="row d-flex align-items-center">
						<div className="col-md-7 order-last order-md-2 container-blocks">
							<div className="fbox-13-wrapper wow fadeInRight">	
								<div className="row">
				 					<div className="col-md-6">
				 						<div id="fb-12-1" className="fbox-12 bg--white-100 block-shadow r-12 mb-30">
											<div className="fbox-ico ico-50">
												<div className="shape-ico color--theme">
													{/* <span className="flaticon-graphics"></span>
													<svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
													 <path d="M69.8,-23C76.3,-2.7,57.6,25.4,32.9,42.8C8.1,60.3,-22.7,67,-39.1,54.8C-55.5,42.7,-57.5,11.7,-48.6,-11.9C-39.7,-35.5,-19.8,-51.7,5.9,-53.6C31.7,-55.6,63.3,-43.2,69.8,-23Z" transform="translate(100 100)" />
													</svg> */}
													<img src={inventory} alt="inventory" className="image-inventory" />
												</div>
											</div>
											<div className="fbox-txt">
												<h5 className="s-24 w-500">Inventory <br /> Management</h5>
												<p className="s-18 w-400">Real-time stock management with multiple stores.</p>
											</div>

				 						</div>

				 						<div id="fb-12-2" className="fbox-12 bg--white-100 block-shadow r-12">
											<div className="fbox-ico ico-50">
												<div className="shape-ico color--theme">
													{/* <span className="flaticon-idea"></span> */}
													<img src={Production} alt="icon-production" />
													{/* <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
													  <path d="M69.8,-23C76.3,-2.7,57.6,25.4,32.9,42.8C8.1,60.3,-22.7,67,-39.1,54.8C-55.5,42.7,-57.5,11.7,-48.6,-11.9C-39.7,-35.5,-19.8,-51.7,5.9,-53.6C31.7,-55.6,63.3,-43.2,69.8,-23Z" transform="translate(100 100)" />
													</svg> */}

												</div>
											</div>
											<div className="fbox-txt">
												<h5 className="s-24 w-500">Production Management</h5>
												<p className="s-18 w-400">Create Bill of Materials (BOM) and manage production with ease.</p>
											</div>

				 						</div>

				 					</div>


				 					<div className="col-md-6">
				 						<div id="fb-12-3" className="fbox-12 bg--white-100 block-shadow r-12 mb-30">
											<div className="fbox-ico ico-50">
												<div className="shape-ico color--theme">
													{/* <span className="flaticon-graphic"></span>
													<svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
													  <path d="M69.8,-23C76.3,-2.7,57.6,25.4,32.9,42.8C8.1,60.3,-22.7,67,-39.1,54.8C-55.5,42.7,-57.5,11.7,-48.6,-11.9C-39.7,-35.5,-19.8,-51.7,5.9,-53.6C31.7,-55.6,63.3,-43.2,69.8,-23Z" transform="translate(100 100)" />
													</svg> */}
													<img src={sales} alt="sales-and-management" />
												</div>
											</div>
											<div className="fbox-txt">
												<h5 className="s-24 w-600">Sales and Purchase Management</h5>
												<p className="s-18 w-400">Manage sale and purchase documents as per teams.</p>
											</div>

				 						</div>
				 						<div id="fb-12-4" className="fbox-12 bg--white-100 block-shadow r-12">
											<div className="fbox-ico ico-50">
												<div className="shape-ico color--theme">
													{/* <span className="flaticon-search-engine-1"></span>
													<svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
													  <path d="M69.8,-23C76.3,-2.7,57.6,25.4,32.9,42.8C8.1,60.3,-22.7,67,-39.1,54.8C-55.5,42.7,-57.5,11.7,-48.6,-11.9C-39.7,-35.5,-19.8,-51.7,5.9,-53.6C31.7,-55.6,63.3,-43.2,69.8,-23Z" transform="translate(100 100)" />
													</svg> */}
                                                    <img src={user} alt="user-management" />
												</div>
											</div>
											<div className="fbox-txt">
												<h5 className="s-24 w-600">User and Reporting Management</h5>
												<p className="s-18 w-400">Create multiple users and manage teams.</p>
											</div>

				 						</div>

				 					</div>


				 				</div>
							</div>
						</div>

						<div className="col-md-5 order-first order-md-2 container-summary">
							<div className="txt-block left-column wow fadeInLeft">
								<h2 className="s-46 w-700">Reorganize your work processes with our unique features</h2>
								<div className="cbox-1 ico-15">

		 							<div className="ico-wrap color--theme">
		 								<div className="cbox-1-ico"><span className="flaticon-check"></span></div>
		 							</div>

									<div className="cbox-1-txt">
									<p><b>Enhance Manufacturing Performance: </b>Ease Margin's solution helps to improve	productivity for small and medium-sized manufacturers</p>
									</div>
								</div>
								<div className="cbox-1 ico-15">

		 							<div className="ico-wrap color--theme">
		 								<div className="cbox-1-ico"><span className="flaticon-check"></span></div>
		 							</div>

									<div className="cbox-1-txt">
									<p><b>Comprehensive Features: </b>Real-time inventory and production management, sales and purchase tracking, and effective user management.</p>
									</div>
								</div>
								<div className="cbox-1 ico-15">
		 							<div className="ico-wrap color--theme">
		 								<div className="cbox-1-ico"><span className="flaticon-check"></span></div>
		 							</div>

									<div className="cbox-1-txt">
									<p><b>Powerful Reporting: </b> Provides advanced and accurate reporting tools for making decisions based on data.</p>
									</div>
								</div>
								<div className="cbox-1 ico-15">

		 							<div className="ico-wrap color--theme">
		 								<div className="cbox-1-ico"><span className="flaticon-check"></span></div>
		 							</div>

									<div className="cbox-1-txt">
									<p><b>Easy Integration: </b> Connects with multiple accounting software and API access for integrations based on need.</p>
									</div>
								</div>
								<div className="cbox-1 ico-15">
		 							<div className="ico-wrap color--theme">
		 								<div className="cbox-1-ico"><span className="flaticon-check"></span></div>
		 							</div>

									<div className="cbox-1-txt">
									<p><b>User-friendly Experience: </b> Provides an easy interface, customization options, and all-time high-quality user support for a seamless experience.</p>
									</div>
								</div>
								{/* <ul className="simple-list">

									<li className="list-item">
										<p>Tempor sapien quaerat undo ipsum laoreet diam purus sapien a dolor ociis ultrice 
											ipsum aliquam congue a dolor cursus congue varius magnis
										</p>
									</li>

									<li className="list-item">
										<p className="mb-0">Cursus purus suscipit  vitae cubilia magnis diam volute egestas
											sapien ultrice auctor
										</p>
									</li>

								</ul> */}

							</div>
						</div>
					</div>
				</div>
			</section>
        </> );
    }
}
 
export default Features;
