import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import history from "../History/History";
import HomePage from "./HomePage";
import PublicRoute from "./Route/PublicRoute";
import Signin from "./Signin";
import SignUp from "./Signup";
import PrivateRoute from "./Route/PrivateRoute";
import Main from "./Main";
import TermCondition from "./TermCondition";
import Privacy from "./Privacy";
import BlogDetails from "./BlogDetails";
import ScrollToTop from "./ScrollToTop";
import Blogs from "./Blogs";

const AppRoutes: React.FC = () => {

    return (
        <Router>
            <ScrollToTop>
                <Routes>
                    <Route path="/" element={<PublicRoute><HomePage /></PublicRoute>} />
                    <Route path="/sign-in" element={<PublicRoute><Signin /></PublicRoute>} />
                    <Route path="sign-up" element={<PublicRoute><SignUp /></PublicRoute>} />
                    <Route path="/dashboard" element={<PrivateRoute><Main /></PrivateRoute>} />
                    <Route path="/buyer_supplier" element={<PrivateRoute><Main /></PrivateRoute>} />
                    <Route path="/items" element={<PrivateRoute><Main /></PrivateRoute>} />
                    <Route path="/stores" element={<PrivateRoute><Main /></PrivateRoute>} />
                    <Route path="/settings" element={<PrivateRoute><Main /></PrivateRoute>} />
                    <Route path="/user_management" element={<PrivateRoute><Main /></PrivateRoute>} />
                    <Route path="/team_management" element={<PrivateRoute><Main /></PrivateRoute>} />
                    <Route path="/profile_management" element={<PrivateRoute><Main /></PrivateRoute>} />
                    <Route path="/terms-and-condition" element={<TermCondition />} />
                    <Route path="/privacy-policy" element={<Privacy />} />
                    <Route path="/our-blogs" element={<PublicRoute><Blogs /></PublicRoute>} />
                    <Route path="/manage_blogs" element={<PrivateRoute><Main /></PrivateRoute>} />
                    <Route path="/permissions" element={<PrivateRoute><Main /></PrivateRoute>} />
                    <Route path="/blog_details/:id" element={<PublicRoute><BlogDetails /></PublicRoute>} />
                    <Route path="/documents" element={<PublicRoute><Main /></PublicRoute>} />
                    <Route path="/create_document" element={<PublicRoute><Main /></PublicRoute>} />
                    <Route path="/previewDocument" element={<PublicRoute><Main /></PublicRoute>} />
                    <Route path="/comments" element={<PublicRoute><Main /></PublicRoute>} />
                </Routes>
            </ScrollToTop>
        </Router>
    )
}

export default AppRoutes