import { Breadcrumb, Button, Layout, Watermark, WatermarkProps } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { getDocumentById } from '../../../redux/actions/API/documents';
import './PreviewDocument.css';
import { Link, useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined, DownloadOutlined, PrinterOutlined, EditOutlined, MailOutlined } from "@ant-design/icons";
import logo from "../../../Utility/images/ease/transparent/ease-primary.png";
import signPlaceholder from '../../../Utility/images/signPlaceholder.png'
import { Color } from 'antd/es/color-picker';
interface ITotals {
  tax: number;
  totalBeforeTax: number;
  totalTax: number;
  totalAfterTax: number;
  grandTotal: number;
}

interface IPreviewDocumentProps {
  getDocumentById: Function;
  dataToPreview: any;
  companyId: string;
  companyName: string,
}

const PreviewDocument: React.FC<IPreviewDocumentProps> = ({ getDocumentById, dataToPreview, companyId, companyName }) => {
  const quotationRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const url = window.location.href;
    const params = new URLSearchParams(url.split('?')[1]);
    const documentNumber = params.get('documentNumber');

    if (documentNumber) {
      getDocumentById({ documentNumber, companyId: Number(companyId) });
    }
  }, [getDocumentById, companyId]);

  const downloadPDF = async () => {
    const element = quotationRef.current;
    if (element) {
      const html2pdf = (await import('html2pdf.js')).default;

      html2pdf()
        .from(element)
        .set({
          margin: 0,
          filename: 'quotation.pdf',
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
        })
        .save();
    }
  };

  function numberToWords(num: number): string {
    const belowTwenty: string[] = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine", "Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];
    const tens: string[] = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];
    const thousands: string[] = ["", "Thousand", "Million", "Billion"];

    let words: string = "";

    const getWords = (n: number): string => {
      if (n < 20) return belowTwenty[n];
      if (n < 100) return tens[Math.floor(n / 10)] + (n % 10 ? " " + belowTwenty[n % 10] : "");
      if (n < 1000) return belowTwenty[Math.floor(n / 100)] + " Hundred" + (n % 100 ? " and " + getWords(n % 100) : "");
      return "";
    };

    if (num === 0) return "Zero";

    let integerPart: number = Math.floor(num);
    let decimalPart: number = Math.round((num - integerPart) * 100); // For paisa

    let i = 0;
    while (integerPart > 0) {
      let chunk = integerPart % 1000;
      if (chunk > 0) {
        words = getWords(chunk) + (thousands[i] ? " " + thousands[i] : "") + (words ? " " + words : "");
      }
      integerPart = Math.floor(integerPart / 1000);
      i++;
    }

    if (decimalPart > 0) {
      if (words) words += " and ";
      words += getWords(decimalPart) + " Paisa";
    }

    return words + " Only";
  }

  const editDocument = () => {
    console.log("Document Edit Clicked", dataToPreview)
    // navigate("/create_document?documentType=salesQuotation");
  };

  return (
    <Layout className='layout'>
      <div>
        <Breadcrumb
          style={{
            margin: "16px 0",
            flex: 1,
          }}
        >
          <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
          <Breadcrumb.Item>Sales</Breadcrumb.Item>
          <Breadcrumb.Item>Documents</Breadcrumb.Item>
          <Breadcrumb.Item>Preview Document</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="flexBox mt-20">
        <div className="divDocuments">
          <Link to="/documents" className="linkToDocs">
            <p className="textDocument">
              <ArrowLeftOutlined /> {dataToPreview?.documentNumber}
            </p>
          </Link>
        </div>
        <div className="flexBox">
          {dataToPreview?.status == 1 && <>
            <Button type="link" onClick={downloadPDF}>
              <DownloadOutlined /> Download
            </Button>
            <Button
              type="link"
              onClick={() => {
                window.print();
              }}
            >
              <PrinterOutlined /> Print
            </Button>
            <Button type="link">
              <MailOutlined /> Email
            </Button>
          </>}
          <Button type="primary" onClick={editDocument}>
            <EditOutlined /> Edit
          </Button>
        </div>
      </div>
      {/* <Content className="content-permission p-20"> */}
      {dataToPreview ? (
        <div className="documentContainer" id="documentContainer" ref={quotationRef}>
          {dataToPreview.status == 0 && <div className='watermark'>Draft</div>}
          <div className="documentHeader">
            <div>
              <h2>{dataToPreview.documentType}</h2>
              <div className='mt-10 title'>Company Name: {companyName}</div>
              <div className='title'>Contact Number: +91-9876543210</div>
              <div className='title'>Address: {dataToPreview?.supplierBillingAddress}</div>

              <div className='mt-20 title'>Quotation Number: {dataToPreview?.documentNumber}</div>
              <div className='title'>
                Quotation Date: {new Date(dataToPreview?.documentDate).toLocaleDateString("en-GB")}
              </div>
            </div>
            <div>
              <img
                src={logo}
                className="logo"
                alt="Ease Margin"
                style={{ margin: "0px 0px 0px -30px", height: "70px" }}
              />
            </div>
          </div>
          <h6>Buyer Details</h6>
          <div className="buyerSupplierContainer mt-10">
            <div className="supplierDetails" style={{maxWidth: '40%'}}>
              <div>Company Name: {dataToPreview?.buyerName}</div>
              <div>Contact Number: {dataToPreview.buyerContactNumber}</div>
              <div>Billing Address: {dataToPreview?.buyerBillingAddress}</div>
              <div>Delivery Address: {dataToPreview?.buyerDeliveryAddress} </div>
            </div>
            <div className="flexBox supplierDetails">
              <div>
                <div>Enquiry No.: {dataToPreview?.enquiryNumber}</div>
                <div>Enquiry Date: {new Date(dataToPreview?.enquiryDate).toLocaleDateString("en-GB")}</div>
                <div>Exp. Delivery Date: {new Date(dataToPreview?.deliveryDate).toLocaleDateString("en-GB")}</div>
                <div>Payment Term: {dataToPreview?.paymentTerm} </div>
                <div>Reply Date: {dataToPreview?.replyDate} </div>
                <div>Return Recieve Date: {dataToPreview?.returnRecieveDate} </div>
                <div>Invoice Date: {dataToPreview?.invoiceDate} </div>
              </div>
              <div>
                <div>OC Number: {dataToPreview?.OCNumber}</div>
                <div>OC Date: {new Date(dataToPreview?.OCDate).toLocaleDateString("en-GB")}</div>
                <div>POC Date: {new Date(dataToPreview?.POCDate).toLocaleDateString("en-GB")}</div>
                <div>POC Name: {dataToPreview?.POCName} </div>
                <div>POC Number: {dataToPreview?.POCNumber} </div>
                <div>Transporter name: {dataToPreview?.transporterName} </div>
                <div>Invoice No: {dataToPreview?.invoiceNumber} </div>
              </div>
              <div>
                <div>TD No.: {dataToPreview?.TDNumber}</div>
                <div>TD Date: {new Date(dataToPreview?.TDDate).toLocaleDateString("en-GB")}</div>
                <div>TG No: {dataToPreview?.TGNumber}</div>
                <div>Vehicle No: {dataToPreview?.VehicleNumber} </div>
                <div>Bill Date: {dataToPreview?.billDate} </div>
                <div>Reply Date: {dataToPreview?.replyDate} </div>
                <div>Exp. Payment Date: {dataToPreview?.paymentDate} </div>
              </div>
            </div>
          </div>
          <table className="documentTable" style={{ width: '100%', borderCollapse: 'collapse', border: '1px solid black' }}>
            <thead>
              <tr>
                <th style={{ border: '1px solid black', padding: '8px' }}>S.No</th>
                <th style={{ border: '1px solid black', padding: '8px' }}>Item Description</th>
                <th style={{ border: '1px solid black', padding: '8px' }}>UOM</th>
                <th style={{ border: '1px solid black', padding: '8px' }}>Qty.</th>
                <th style={{ border: '1px solid black', padding: '8px' }}>Price</th>
                <th style={{ border: '1px solid black', padding: '8px' }}>Discount %</th>
                <th style={{ border: '1px solid black', padding: '8px' }}>Total Discount %</th>
                <th style={{ border: '1px solid black', padding: '8px' }}>Tax Type</th>
                <th style={{ border: '1px solid black', padding: '8px' }}>Tax %</th>
                <th style={{ border: '1px solid black', padding: '8px' }}>Total Tax</th>
                <th style={{ border: '1px solid black', padding: '8px' }}>Total Before Tax</th>
                <th style={{ border: '1px solid black', padding: '8px' }}>Total After Tax</th>
              </tr>
            </thead>
            <tbody>
              {dataToPreview?.items?.map((item: any, index: number) => (
                <tr key={item.id}>
                  <td style={{ border: '1px solid black', padding: '8px' }}>{index + 1}</td>
                  <td style={{ border: '1px solid black', padding: '8px' }}>
                    <span>{item.itemName}</span>
                    <br />
                    <span>{item.HSN !== "" ? "HSN Code - " + item.HSN : ""}</span>
                  </td>
                  <td style={{ border: '1px solid black', padding: '8px' }}>{item.UOM}</td>
                  <td style={{ border: '1px solid black', padding: '8px' }}>{item.quantity}</td>
                  <td style={{ border: '1px solid black', padding: '8px' }}>₹ {Number(item.price).toFixed(2)}</td>
                  <td style={{ border: '1px solid black', padding: '8px' }}>{item.discountOne}% - {item.discountTwo}%</td>
                  <td style={{ border: '1px solid black', padding: '8px' }}>{Number(item?.totalDiscount).toFixed(2)}%</td>
                  <td style={{ border: '1px solid black', padding: '8px' }}>{item.taxType}</td>
                  <td style={{ border: '1px solid black', padding: '8px' }}>{item.tax}%</td>
                  <td style={{ border: '1px solid black', padding: '8px' }}>₹ {Number(item.totalTax).toFixed(2)}</td>
                  <td style={{ border: '1px solid black', padding: '8px' }}>₹ {Number(item.totalBeforeTax).toFixed(2)}</td>
                  <td style={{ border: '1px solid black', padding: '8px' }}>₹ {Number(item.totalAfterTax).toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>

          <table className="documentTable" style={{ width: '100%', borderCollapse: 'collapse', border: '1px solid black' }}>
            <thead>
              <tr>
                <th style={{ border: '1px solid black', padding: '8px' }}>S.No</th>
                <th style={{ border: '1px solid black', padding: '8px' }}>Additional Charges</th>
                <th style={{ border: '1px solid black', padding: '8px' }}>Price</th>
                <th style={{ border: '1px solid black', padding: '8px' }}>Tax%</th>
                <th style={{ border: '1px solid black', padding: '8px' }}>Total Charge</th>
              </tr>
            </thead>
            <tbody>
              {dataToPreview?.additionalCharges?.map((item: any, index: number) => (
                <tr key={item.key}>
                  <td style={{ border: '1px solid black', padding: '8px' }}>{index + 1}</td>
                  <td style={{ border: '1px solid black', padding: '8px' }}>{item.chargingFor}</td>
                  <td style={{ border: '1px solid black', padding: '8px' }}>₹ {item.price}</td>
                  <td style={{ border: '1px solid black', padding: '8px' }}>{item.tax}</td>
                  <td style={{ border: '1px solid black', padding: '8px' }}>₹ {item.total}</td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="logisticDetails" style={{ columnGap: '100px' }}>
            <div className="logistic-info-section">
              <div className="bankDetails">
                <h6>Bank Details</h6>
                <table style={{ width: '100%', borderCollapse: 'collapse', border: '1px solid black' }} className='mt-10'>
                  <tbody>
                    <tr>
                      <td style={{ border: '1px solid black', padding: '8px' }} className="fw-500">Bank Name:</td>
                      <td style={{ border: '1px solid black', padding: '8px' }}>{dataToPreview.bankDetails[0].bankName}</td>
                    </tr>
                    <tr>
                      <td style={{ border: '1px solid black', padding: '8px' }} className="fw-500">Account Holder Name:</td>
                      <td style={{ border: '1px solid black', padding: '8px' }}>{dataToPreview.bankDetails[0].accountName}</td>
                    </tr>
                    <tr>
                      <td style={{ border: '1px solid black', padding: '8px' }} className="fw-500">Account Number:</td>
                      <td style={{ border: '1px solid black', padding: '8px' }}>{dataToPreview.bankDetails[0].accountNumber}</td>
                    </tr>
                    <tr>
                      <td style={{ border: '1px solid black', padding: '8px' }} className="fw-500">SWIFT Code:</td>
                      <td style={{ border: '1px solid black', padding: '8px' }}>{dataToPreview.bankDetails[0].SWIFTCode}</td>
                    </tr>
                    <tr>
                      <td style={{ border: '1px solid black', padding: '8px' }} className="fw-500">IFSC Code:</td>
                      <td style={{ border: '1px solid black', padding: '8px' }}>{dataToPreview.bankDetails[0].IFSCCode}</td>
                    </tr>
                    <tr>
                      <td style={{ border: '1px solid black', padding: '8px' }} className="fw-500">MICR Code:</td>
                      <td style={{ border: '1px solid black', padding: '8px' }}>{dataToPreview.bankDetails[0].MICRCode}</td>
                    </tr>
                    <tr>
                      <td style={{ border: '1px solid black', padding: '8px' }} className="fw-500">Branch:</td>
                      <td style={{ border: '1px solid black', padding: '8px' }}>{dataToPreview.bankDetails[0].branch}</td>
                    </tr>
                    <tr>
                      <td style={{ border: '1px solid black', padding: '8px' }} className="fw-500">Address:</td>
                      <td style={{ border: '1px solid black', padding: '8px' }}>{dataToPreview.bankDetails[0].address}</td>
                    </tr>
                  </tbody>
                </table>
              </div> 

              <h6 className='mt-20'>Logistic Details</h6>
              <p>{dataToPreview?.logisticDetails || "No logistic details"}</p>

              <h6 className="mt-20">Additional Details</h6>
              <p>
                {dataToPreview?.additionalDetails || "No additional details"}
              </p>
              <h6 className="mt-20">Attachments</h6>
              <p>No attachment available</p>

              <h6>Terms and Conditions</h6>
              <p>{dataToPreview?.termsCondition}</p>

            </div>
            <div className="totals" style={{ marginTop: '25px' }}>
              <div className="total-item">
                <span className="fw-500">Total Before Tax</span>
                <span className="fw-500">
                  ₹ {dataToPreview?.items?.reduce((acc: number, item: any) => acc + Number(item.totalBeforeTax), 0).toFixed(2) ?? 0}
                </span>
              </div>
              <div className="total-item">
                <span className="fw-500">Total Tax (CGST and SGST)</span>
                <span className="fw-500">
                  ₹ {dataToPreview?.items?.reduce((acc: number, item: any) => acc + Number(item.totalTax), 0).toFixed(2) ?? 0}
                </span>
              </div>
              <div className="total-item">
                <span className="fw-500">Total After Tax</span>
                <span className="fw-500">
                  ₹ {dataToPreview?.items?.reduce((acc: number, item: any) => acc + Number(item.totalAfterTax), 0).toFixed(2) ?? 0}
                </span>
              </div>
              <div className="total-item">
                <span className="fw-500">Additional Charges</span>
                <span className="fw-500">
                  ₹ {dataToPreview?.additionalCharges?.reduce((acc: number, charge: any) => acc + charge.total, 0).toFixed(2) ?? 0}
                </span>
              </div>
              <div className="total-item grand-total">
                <span className="fw-500">Grand Total</span>
                <span className="fw-500">
                  ₹ {(
                    dataToPreview?.items?.reduce((acc: number, item: any) => acc + Number(item.totalAfterTax), 0) +
                    dataToPreview?.additionalCharges?.reduce((acc: number, charge: any) => acc + Number(charge.total), 0)
                  ).toFixed(2) ?? 0}
                </span>
              </div>
              <div className="signature total-item grand-total mt-20">
                <div></div>
                <div>
                  <img src={signPlaceholder} alt="Signature" />
                  <p>Authorized Signature</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <p>Data not available</p>
      )}
      {/* </Content> */}
    </Layout>
  );
};

const mapStateToProps = (state: any) => ({
  companyId: state.api.login?.data?.companyId,
  dataToPreview: state.api.getDocumentById?.data,
  companyName: state.api.login.data.companyName,
});

const mapDispatchToProps = (dispatch: any) => ({
  getDocumentById: (payload: any) => dispatch(getDocumentById(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PreviewDocument);
