import { Layout, Breadcrumb, Card, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import "./SalesQuotation.css";
import { Content } from "antd/es/layout/layout";
import { Link } from "react-router-dom";
import CompanyMetaDataModal from "./CompanyMetaDataModal";
import { getItems } from "../../../redux/actions/API/items";
import { connect } from "react-redux";
import TermsAndCondition from "../documentTermsAndCondition/TermsAndCondition";
import { AppConstants } from "../../../Appconstants";
import DocumentItemGrid from "../documentItemGrid/DocumentItemGrid";
import { getStores } from "../../../redux/actions/API/storesActions";
import AdditionalCharge from "../documentAdditionalCharge/AdditionalCharge";
import { setDocumentCompanyData, setDocumentSupplierData } from "../../../redux/actions/UI/createDocument";
import { getAddress } from "../../../redux/actions/API/addressActions";
import { EditOutlined } from "@ant-design/icons";
import SupplierAddressModal from "./SupplierAddressModal";
import { getDocumentSeries } from "../../../redux/actions/API/documentSeriesAction";
import DocumentMetaDataForm from "./DocumentMetaDataForm";
import './Document.css';
import DocumentDetails from "./DocumentDetails";
import TotalCalculation from "../documentTotalCalculation/TotalCalculation";
import LogisticDetails from "../documentLogisticDetails/LogisticDetails";
import AdditionalDetails from "../documentAdditionalDetails/AdditionalDetails";

interface ISalesQuotationProps {
  getItems: Function;
  companyId: Number;
  userId: Number;
  getStores: Function;
  setDocumentCompanyData: Function;
  setDocumentSupplierData: Function;
  getAddress: Function;
  itemsData: any;
  buyerDetails: any;
  supplierDetails: any;
  companyName: string;
  companyEmail: string;
  documentType: string;
}

const CreateDocument: React.FC<ISalesQuotationProps> = ({
  getItems,
  companyId,
  getStores,
  setDocumentCompanyData,
  setDocumentSupplierData,
  buyerDetails,
  supplierDetails,
  companyName,
  companyEmail,
  getAddress,
  documentType
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isSupplierModalOpen, setIsSupplierModalOpen] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [changeAddressModalTitle, setChangeAddressModalTitle] = useState<string>("");
  const [documentValidationData, setDocumentValidationData] = useState(null);
  const [documentItemValidationData, setDocumentItemValidationData] = useState(null);

  useEffect(() => {
    getAddress({ companyId: Number(companyId) });
  }, []);

  useEffect(() => {
    if (companyId) {
      getItems({ companyId: Number(companyId) });
      getStores({ companyId: Number(companyId) });
    }
  }, [companyId, getItems, getStores]);

  const handleCancel = () => {
    setIsModalOpen(false);
    setIsEditing(false);
  };

  const onSubmit = (companyData: any) => {
    setDocumentCompanyData(companyData);
    setIsModalOpen(false);
  };

  const handleSupplierEdit = () => {
    setIsSupplierModalOpen(true);
    setChangeAddressModalTitle("Change Supplier's Addresses");
  };

  const handleSaveSupplierAddress = (supplierData: any) => {
    setDocumentSupplierData(supplierData);
    setIsSupplierModalOpen(false);
  };

  const handleSupplierCancel = () => {
    setIsSupplierModalOpen(false);
  };

  const handleBuyerEdit = () => {
    setChangeAddressModalTitle("Change Buyer's Addresses");
    setIsEditing(true);
    setIsModalOpen(true);
  };

  const checkIsDocumentValid = (data: any) => {
    setDocumentValidationData(data);
    setDocumentItemValidationData(data)
  }

  return (
    <>
      {buyerDetails && (
        <Layout className="layout">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Breadcrumb style={{ margin: "20px 0", flex: 1 }}>
              <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
              <Breadcrumb.Item>Sales</Breadcrumb.Item>
              <Breadcrumb.Item>Documents</Breadcrumb.Item>
              <Breadcrumb.Item>{documentType}</Breadcrumb.Item>
            </Breadcrumb>
          </div>

          <div className="divDocuments mt-10">
            <Link to="/documents" className="linkToDocs">
              <p className="textDocument">
                <ArrowLeftOutlined /> {AppConstants.BACK_TO_DOCUMENTS_BUTTONS}
              </p>
            </Link>
          </div>
          <Content style={{overflow: 'auto'}}>
            <div className="addressContainer">
              <div className="halfGrow">
                <h6 className="title mb-10">Supplier's Details</h6>
                <Card className="addressCard">
                  <div className="flexBox">
                    <h6>{companyName}</h6>
                    <Tooltip title="Change Supplier's Billing and Delivery address">
                      <p onClick={handleSupplierEdit} className="actionIcons">
                        <EditOutlined />
                      </p>
                    </Tooltip>
                  </div>
                  <div>Email: {companyEmail}</div>
                  <div>Contact Number: { }</div>
                  <div>
                    Delivery Address : {supplierDetails?.supplierDeliveryAddress}
                  </div>
                  <div>Billing Address : {supplierDetails?.supplierBillingAddress}</div>
                </Card>
              </div>
              <div className="halfGrow">
                <h6 className="title mb-10">Buyer's Details</h6>
                <Card className="addressCard billingAddressCard">
                  <div className="flexBox">
                    <h6>{buyerDetails?.buyerName}</h6>
                    <Tooltip title="Change Buyer's Billing and Delivery address">
                      <p onClick={handleBuyerEdit} className="actionIcons">
                        <EditOutlined />
                      </p>
                    </Tooltip>
                  </div>
                  <div>Email: {buyerDetails.buyerEmail}</div>
                  <div>Contact Number: {buyerDetails.buyerContactNumber}</div>
                  <div>Delivery Address : {buyerDetails?.buyerDeliveryAddress} </div>
                  <div>Billing Address : {buyerDetails?.buyerBillingAddress} </div>
                </Card>
              </div>
            </div>

            <Card className="cardForm">
              <DocumentMetaDataForm documentValidationData={documentValidationData} />
            </Card>

            <div className="table-sales-quotation">
              <DocumentItemGrid documentItemValidationData={documentItemValidationData}/>
            </div>

            <Card className="cardForm">
              <AdditionalCharge />
            </Card>

            <div className="mt-10">
              <div className="flexBox">
                <LogisticDetails />
                <AdditionalDetails />
              </div>
            </div>

            <div className="mt-10">
              <DocumentDetails />
            </div>
            <Card className="mt-10 cardForm p-20 br-10">
              <div className="flexBox">
                <TermsAndCondition />
                <TotalCalculation checkIsDocumentValid={checkIsDocumentValid} />
              </div>
            </Card>

          </Content>
        </Layout>
      )}
      <CompanyMetaDataModal
        title={changeAddressModalTitle}
        isModalOpen={isModalOpen}
        handleSubmit={onSubmit}
        handleCancel={handleCancel}
        selectedCompanyNameAsProps={buyerDetails?.buyerName}
        isEditing={isEditing}
        buyerDefaultBillId={buyerDetails?.buyerSelectedBillingAddressId}
        buyerDefaultDelId={buyerDetails?.buyerSelectedDeliveryAddressId}
      />
      <SupplierAddressModal
        title={changeAddressModalTitle}
        isSupplierModalOpen={isSupplierModalOpen}
        handleSaveSupplierAddress={handleSaveSupplierAddress}
        handleSupplierAddressCancel={handleSupplierCancel}
        supplierDefaultBillId={supplierDetails?.supplierSelectedDeliveryAddressId}
        supplierDefaultDelId={supplierDetails?.supplierSelectedBillingAddressId}
      />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  buyerDetails: state.ui.createDocumentData.buyerDetails,
  supplierDetails: state.ui.createDocumentData.supplierDetails,
  itemsData: state.api.getItems?.data?.reverse(),
  userId: state.api.login.data.id,
  companyName: state.api.login.data.companyName,
  companyId: state.api.login.data.companyId,
  companyEmail: state.api.login.data.email,
  documentType: state.ui.createDocumentData.documentType,
});

const mapDispatchToProps = (dispatch: any) => ({
  getItems: (payload: any) => dispatch(getItems(payload)),
  getStores: (payload: any) => dispatch(getStores(payload)),
  setDocumentCompanyData: (payload: any) => dispatch(setDocumentCompanyData(payload)),
  getAddress: (payload: any) => dispatch(getAddress(payload)),
  setDocumentSupplierData: (payload: any) => dispatch(setDocumentSupplierData(payload)),
  getDocumentSeries: (payload: any) => dispatch(getDocumentSeries(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateDocument);
